import React from 'react'
import { Link, NavLink } from 'react-router-dom'

import { images } from '../../helpers/images';

export const NavTopLegal = () => {
    return (
        <section id="NavTop-legal">
            <div className="container d-flex align-items-center justify-content-between flex-column flex-lg-row">
                <Link to="/">
                    <img src={images('./logo.png').default ? images('./logo.png').default : images('./logo.png')} className="card-img" alt="logo" id="logo" />
                </Link>
                <ul className="d-flex align-items-center flex-wrap justify-content-center my-3 my-lg-0">
                    <li>
                        <NavLink to='/avis-legal' activeClassName='active'>
                            Avís Legal
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/politica-privacitat' activeClassName='active'>
                            Política de Privacitat
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/politica-cookies' activeClassName='active'>
                            Política de Cookies
                        </NavLink>
                    </li>
                </ul>
            </div>
        </section>
    )
}
