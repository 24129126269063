import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Button, Form, Modal, Table } from 'react-bootstrap'
import { Loader } from '../../../components/Loader/Loader';
import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { InputText } from '../../../components/Inputs';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import IdiomasEcoplatform from '../../../components/IdiomasEcoplatform/IdiomasEcoplatform';
import { MsgInput } from '../../../components/MsgInput/MsgInput';
import AdapterBase from '../../../adapters/datosbancario.adapter';
import Service from '../../../services/datosbancarios.service';
import { ModalStatusServer } from '../../../components/ModalStatusServer/ModalStatusServer';

// import './ProcessosMain.css'

export const ModalDatosBancarios = ({ data, handleCloseModal}) => {
    
    const [fetchGetBancario, fetchGetBancarioData] = Service.Get()

    const [fetchPatchBancario, fetchPatchBancarioData] = Service.Update()

    const [formBancario, 
        handleInputChangeBancario, 
        handleFormChangeBancario, 
        handleResetBancario] = useForm(AdapterBase.schema())

    const [valuesValidBancario, handleVerifiedValuesBancario, handleResetValuesBancario] = useValidator({
        entidad: { isOk: true, msgError: [`Ingrese un código`] },
        num_cuenta: { isOk: true, msgError: [`Ingrese una descripción`] },
    })

    const handleSubmitUpdate = () => {
        if (handleVerifiedValuesBancario(formBancario)) {
            fetchPatchBancario({
                id: formBancario?.id,
                body: {
                    ...formBancario,
                    _method: 'PATCH',
                }
            })
        }
    }

    useEffect(() => {
        if (fetchPatchBancarioData?.status === 201) {
            handleResetValuesBancario()
            //handleCloseModal()
        }
    }, [fetchPatchBancarioData])


    useEffect(() => {
        if(data.value){
            fetchGetBancario()
        }else{
            handleResetBancario();
            handleResetValuesBancario();
        }
    }, [data.value])

     useEffect(() => {
        if (fetchGetBancarioData?.data) {
            const data = fetchGetBancarioData.data?.data;
            handleFormChangeBancario({...data})
        }
    }, [fetchGetBancarioData.data])


    return (
        <>
        <Modal show={data.value} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Actualizar datos bancarios</Modal.Title>
            </Modal.Header>
            {fetchGetBancarioData.loading ? <Loader /> : <>
            <Modal.Body className='px-4 py-4'>
                <Form className='row'>
                    <Form.Group className="mb-3 col-lg-12">
                        <InputText
                            label='Entidad:'
                            className=''
                            name='entidad'
                            placeholder="Ingrese una entidad banacaria"
                            value={formBancario.entidad}
                            onChange={handleInputChangeBancario}
                            validation={valuesValidBancario}
                            fetchData={fetchPatchBancarioData}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3 col-lg-12">
                        <InputText
                            label='Número de cuenta:'
                            className=''
                            name='num_cuenta'
                            placeholder="Ingrese un código"
                            value={formBancario.num_cuenta}
                            onChange={handleInputChangeBancario}
                            validation={valuesValidBancario}
                            fetchData={fetchPatchBancarioData}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <ButtonSpinner
                    variant="primary" type="submit" className="ms-2"
                    handleSubmit={handleSubmitUpdate} fetchDataLoading={[fetchPatchBancarioData.loading]}
                    value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                />
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
            </>
         }
        </Modal>
        <ModalStatusServer fetchData={fetchPatchBancarioData}/>
        </>
    )
}
