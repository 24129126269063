import { useAxios2 } from "../hooks/useAxios2";
import SchemaBase from '../adapters/datosbancario.adapter'

const Get = () => {
    return useAxios2('get', '/datos_bancario', 'json', SchemaBase.adaterGeneral, SchemaBase.schema);
};

const Update = () => {
    return useAxios2('patch', `/datos_bancario`, 'json', {
        receiveAdapter: SchemaBase.adaterGeneral,
        sendAdapter: SchemaBase.schema
    });
};

export default {
  Get,
  Update,
};