import React from 'react';
import ReactDOM from 'react-dom';
import { Dapcons } from './Dapcons';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

console.log(process.env.REACT_APP_API_URL)
ReactDOM.render(
  <Dapcons />,
  document.getElementById('root')
);
