import React, { createContext, useContext, useCallback } from 'react';
import { useAxios } from '../hooks/useAxios';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [fetchNotificaciones, fetchNotificacionesData] = useAxios('GET', '/obtener-notificaciones');

  const memoizedFetchNotificaciones = useCallback(() => {
    fetchNotificaciones();
  }, [fetchNotificaciones]);

  return (
    <NotificationContext.Provider value={{ memoizedFetchNotificaciones, fetchNotificacionesData}}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
