import React, { useContext, useEffect, useState } from 'react'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { useAxios } from '../../../hooks/useAxios'
import { useForm } from '../../../hooks/useForm'
import { RCPsFilter } from '../RCPsFilter/RCPsFilter'
import { RCPsMain } from '../RCPsMain/RCPsMain'
import queryString from 'query-string';
import { AuthContext } from '../../../auth/AuthContext'
import { GetRCPsJSON } from '../../../services/rcp.service'

export const RCPsContent = ({ namePage, estaVerificando }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const location = useLocation()

    const [fetchParameters, fetchParametersData] = useAxios('GET', `/parametros`)

    const [fetchRCPs, fetchRCPsData] = GetRCPsJSON()

    const [novigentes, setNovigentes] = useState(false);

    const handleChangeNoVigentes = (e) => {
        const novigentes = e.target.checked ? true : false;
        setNovigentes(novigentes);
    }

    useEffect(() => {
        fetchParameters({
            params: {
                'colegios': 1,
                'perfiles_usuario': 1,
                'estados_colegiacion': 1,
            }
        });
        fetchRCPs({
            params:{
                novigentes
            }
        })
    }, [novigentes])

    return (
        <div className="container-inner row justify-content-between">

            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className="row mb-3">
                  <div className="col text-start">
                    <label className="d-flex align-items-center">
                      <input
                        name="novigentes"
                        type="checkbox"
                        checked={novigentes}
                        onChange={handleChangeNoVigentes}
                        disabled={fetchRCPsData.loading}
                        className="form-check-input me-2"
                      />
                      Mostrar no vigente
                    </label>
                  </div>  
                  <div className="col text-end">
                  </div>
            </div>

            {/* <RCPsFilter
                handleSearch={handleSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                formValuesSearch={formValuesSearch}
                fetchParametersData={fetchParametersData}
            /> */}

            <RCPsMain
                fetchRCPsData={fetchRCPsData}
                fetchRCPs={fetchRCPs}
                // formValuesSearch={formValuesSearch}
                namePage={namePage}
                // handleFormChange={handleFormChange}
                fetchParametersData={fetchParametersData}
                modulo_app={modulo_app}
                estaVerificando={estaVerificando}
            />
        </div>

    )
}
