import React, { useEffect, useState } from 'react'
import { PaymentMethodModal } from './PaymentMethodModal.jsx';
import { PaymentModal } from './PaymentModal';
import { PaymentTransferModal } from './PaymentTransferModal'
import { useModalData } from '../../../hooks/useModalData';

export const PaymentContent = (props) => {
  const {isOpen} = props;
  const [metodoPago, setMetodoPago] = useState('');
  const [dataModal_Domicializacion, handleOpenModal_Domicializacion, handleCloseModal_Domicializacion] = useModalData()
  const [dataModal_Transferencia, handleOpenModal_Transferencia, handleCloseModal_Transferencia] = useModalData()
  
  useEffect(() => {
    if(metodoPago && metodoPago === 'R'){
        handleOpenModal_Domicializacion({
            metodo_pago:metodoPago
        })
    }else if(metodoPago && metodoPago === 'T'){
        handleOpenModal_Transferencia({
            metodo_pago:metodoPago
        })
    }
  }, [metodoPago])

	return (
          <>
            <PaymentMethodModal 
              isOpen={isOpen} 
              setMetodoPago={setMetodoPago}
              />
            
            <PaymentModal 
              data={dataModal_Domicializacion}
              handleCloseModal={handleCloseModal_Domicializacion}
              setMetodoPago={setMetodoPago}
            />

            <PaymentTransferModal
              data={dataModal_Transferencia}
              handleCloseModal={handleCloseModal_Transferencia}
              setMetodoPago={setMetodoPago}
            />

          </>
   )
}