import { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../auth/AuthContext';
import { types } from '../types/types';

export const useFetch = (method, urlTemp, type = 'json') => {

    const { user: { token, lang }, dispatch } = useContext(AuthContext);
    // const token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvY2N0aWN3cy5hcmVzY29vcC5lc1wvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTY0MDUyMDczOSwiZXhwIjoxNjQwNjA3MTM5LCJuYmYiOjE2NDA1MjA3MzksImp0aSI6InhPTFlKZWo2cXhiRmlOWHkiLCJzdWIiOjIsInBydiI6IjU4NzA4NjNkNGE2MmQ3OTE0NDNmYWY5MzZmYzM2ODAzMWQxMTBjNGYiLCJ1c2VyIjp7Im5vbWJyZSI6IkRhbmllbCIsImFwZWxsaWRvcyI6IkcuTS4iLCJlbWFpbCI6ImRhbmllbEBhcmVzY29vcC5lcyIsImNvbGVnaW8iOnsiaWQiOjEsIm5vbWJyZSI6IkVCQ04ifSwicGVyZmlsIjp7ImlkIjoiYWRtaW5pc3RyYWRvciIsIm5vbWJyZSI6IkFkbWluaXN0cmFkb3IifX19.v26ayi38E4BUhdBDHrFUi1WeLOK3u0fqdugOG_q4vl0"
    // const lang = "CA"

    const history = useHistory();
    const isMounted = useRef(true);
    const [state, setState] = useState({ data: null, loading: null, error: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const existenErroresAuth = (data) => {
        if (data && token && data.status === 'Error' &&
            (data.message === 'El token ha expirat' ||
                data.message === `No s'ha trobat el token` ||
                data.message === `La sessió no existeix` ||
                data.message === `El token és invàlid` ||
                data.message === `No autoritzat`)) {
            console.log(window.location.pathname)
            if (window.location.pathname.indexOf('/login') === -1) {
                history.replace('/login');
                dispatch({
                    type: types.logout,
                    lang
                });
            }
            return true
        }
        return false
    }

    const handleFetch = (values) => {

        setState({ data: null, loading: true, error: null });

        const requestOptions = {
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        let url = process.env.REACT_APP_API_URL + urlTemp

        if (token) {
            requestOptions.headers.Authorization = 'Bearer ' + token
        }

        if (method === 'POST' || method === 'PUT' || method === 'PATCH') {

            requestOptions.body = JSON.stringify({ ...values?.body })
        }

        if (values?.id) {
            url = url + '/' + values.id
        }

        if (values?.params) {
            let params = values.params
            let query = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
            url = url + '?' + query
        }

        console.log(url)

        fetch(url, requestOptions)
            .then(resp => {
                if (type === 'file')
                    return resp.blob()
                if (type === 'json')
                    return resp.json()
                if (type === 'text')
                    return resp.text()
            })
            .then(data => {
                console.log(data)
                if (!existenErroresAuth(data) && isMounted.current) {
                    setState({
                        loading: false,
                        error: null,
                        data,
                        setState // En caso sea un file, limpiar el state con null luego de la descarga
                    });
                }
            })
            .catch(() => {
                setState({
                    data: null,
                    loading: false,
                    error: 'No se pudo cargar la info'
                })
            })
    }

    return [handleFetch, state];
}