import React, { memo } from 'react'
import { Form } from 'react-bootstrap'

export const MsgInputServer = React.memo(({ name, fetchData, className = '', setErrorServer = (() => null), errorServer, adapter, dynamic }) => {

    // console.log(fetchData?.error?.response?.data?.data)
    let errors = null

    if (fetchData?.error?.response?.data?.data?.[name]) {
        setErrorServer(true)
        errors = fetchData?.error?.response?.data?.data?.[name].map((elem, index) => {
            return (
                <Form.Text key={index} className={`text-danger d-block ${className}`}>{elem}</Form.Text>
            )
        })
    } else if (fetchData?.data?.status === 'Error' && fetchData?.data?.data?.[name]) {
        setErrorServer(true)
        errors = fetchData?.data?.data?.[name].map((elem, index) => {
            return (
                <Form.Text key={index} className={`text-danger d-block ${className}`}>{elem}</Form.Text>
            )
        })
    } else if (fetchData?.error?.response?.data?.message !== 'form_error' && !name) {
        errors = <Form.Text className={`text-danger d-block ${className}`}>{fetchData?.error?.response?.data?.message}</Form.Text>
    } else if (!fetchData?.error?.response?.data?.data && fetchData?.error?.response?.data?.status === 'Error' && !name) {
        // setErrorServer(true)
        errors = <Form.Text className={`text-danger d-block ${className}`}>{fetchData?.error?.response?.data?.message}</Form.Text>
    } else if (dynamic) {
        errors = Object.keys(fetchData?.error?.response?.data?.data).map(key => {
            return (
                fetchData?.error?.response?.data?.data[key].map((item2, index) => {
                    return <Form.Text key={index} className={`text-danger d-block ${className}`}>{item2}</Form.Text>
                })
            )
        })
    }

    return (
        <>
            {errors}
        </>
    )
})
