import React, { useEffect, useState, useContext, useRef  } from 'react'
import { faCreditCard, faTimes, faUniversity, faInfo, faCheck, faArrowRight, faArrowLeft  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Table } from 'react-bootstrap'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { Loader } from '../../../components/Loader/Loader'
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext'
import { useForm } from '../../../hooks/useForm';
import './PaymentInfoTable.css';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import Wizard from "../../../components/Wizard/Wizard";
import jwt from 'jwt-decode' // import dependency
import { types } from '../../../types/types';

export const PaymentTransferModal = ({data, handleCloseModal, setMetodoPago}) => {
    const { user, dispatch } = useContext(AuthContext);
    const [fetchDatosBancarios, fetchDatosBancariosData] = useAxios('GET', `/mostrar_datos_bancarios`)
    const [fetchTarifa, fetchTarifaData] = useAxios('GET', `/organizaciones/detalle_tarifa`)
    const [fetchPagar, fetchPagarData] = useAxios('POST', `/organizaciones/pagar_por_transferencia`);

    useEffect(() => {
        if(data.value){
            fetchDatosBancarios();
            fetchTarifa({
                params:{
                    metodo_pago: 'T'
                }
            });
            setIndexFocusWizard(0)
        }
     },[data.value])

    const [indexFocusWizard, setIndexFocusWizard] = useState(0);
    const listWizard = [
       'Datos Facturación',
       'Datos Pago',
    ];

    const closeModal = () => {
        setMetodoPago('');
        handleCloseModal();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchPagar({
            body: {
                org_metodo_pago: data.metodo_pago
            }
        }); 
    }

    useEffect(() => {
        if(fetchPagarData.data?.data?.data?.token){
            const token = fetchPagarData?.data?.data?.data?.token;
            const lang = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE))?.lang || process.env.REACT_APP_LANGUAGE_DEFAULT;
            const data = jwt(token)
            dispatch({
                type: types.login,
                payload: {
                    ...data,
                    token: token,
                },
                lang
            });
            handleCloseModal();
        }
     },[fetchPagarData.data])

	return (
        <Modal show={data.value} size={'lg'} style={{backdropFilter: ' blur(10px)'}} centered>
            {fetchDatosBancariosData.loading || fetchTarifaData.loading ? <Loader /> : <>
            <Modal.Body>
            <h5 className="text-center">Transferencia Bancaria</h5>  
                  <Wizard>
                    <Wizard.Header
                        listWizard={listWizard}
                        indexFocusWizard={indexFocusWizard}
                        setIndexFocusWizard={setIndexFocusWizard}
                        enableBack={false}
                    />
                    <Wizard.Body indexFocusWizard={indexFocusWizard}>
                     <section className="bg-white">
                          <div className="row d-flex mt-3 justify-content-center align-items-center">
                              <div className="col-12 col-lg-7">
                                    <Table hover responsive>
                                      <tbody>
                                         <tr>
                                            <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                              <strong>Detalle de tarifa:</strong><br/>
                                            </td>
                                            <td>
                                             {fetchTarifaData.data?.data?.data?.detalle || ''}
                                            </td>
                                          </tr>
                                          <tr>
                                                <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                                  <strong>Total :</strong><br/>
                                                </td>
                                                <td>
                                                  € {fetchTarifaData.data?.data?.data?.precio || ''}
                                                </td>
                                           </tr>

                                           <tr>
                                                <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                                  <strong>IVA (21%) :</strong><br/>
                                                </td>
                                                <td>
                                                  € {fetchTarifaData.data?.data?.data?.iva || ''}
                                                </td>
                                            </tr>

                                            <tr>
                                                <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                                  <strong>Total Importe :</strong><br/>
                                                </td>
                                                <td>
                                                  € {fetchTarifaData.data?.data?.data?.importe_total || ''}
                                                </td>
                                            </tr>

                                      </tbody>
                                    </Table>  
                                </div>   
                                <div className="text-center">
                                    <Button variant="outline-primary" className="mt-2 mb-1" size="" onClick={() => setIndexFocusWizard(1)}><FontAwesomeIcon icon={faArrowRight} className='me-1'/>Siguiente</Button>
                                </div>
                            </div> 
                     </section>
                     <section className="bg-white">
                         <div className="row d-flex mt-3 justify-content-center align-items-center">
                              <div className="col-12 col-lg-7">
                             <Table hover responsive>
                                  <tbody>
                                    <tr>
                                    <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                      <strong>Nombre entidad</strong>
                                    </td>
                                    <td>
                                       {fetchDatosBancariosData.data?.data?.data?.nombre_entidad}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                     <strong>Número de cuenta</strong>
                                    </td>
                                    <td>
                                       {fetchDatosBancariosData.data?.data?.data?.numero_cuenta}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                                     <strong>Asunto</strong>
                                    </td>
                                    <td>
                                       Dapcons CIF {user?.user?.usuario || 'No definido'}
                                    </td>
                                  </tr>
                                </tbody>
                            </Table>   
                            </div>
                            <div className="text-center">
                                <Button variant="outline-secondary" size="" onClick={() => setIndexFocusWizard(0)}><FontAwesomeIcon icon={faArrowLeft} className='me-1'/>Regresar</Button>
                                <ButtonSpinner
                                    variant="success" type="submit" className="ms-2"
                                    handleSubmit={handleSubmit} fetchDataLoading={[fetchPagarData.loading]}
                                    value='Confirmar' icon={<FontAwesomeIcon icon={faUniversity} className='me-1' />}
                                />
                            </div>
                         </div>    
                     </section>
                  </Wizard.Body>
                 </Wizard>    
                 <div className="col-12 text-center">
                    <MsgInputServer fetchData={fetchPagarData} className='mt-2' />
                 </div>  
            </Modal.Body>
            <Modal.Footer>
                 <Button variant="outline-dark" className="mt-2 mb-1" size="sm" onClick={closeModal}>
                    Cancelar
                </Button>
            </Modal.Footer> 
             </>
          }  
        </Modal>
    )
}