import { faCheck, faCheckCircle, faFile, faHome, faUser, faTrash} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState} from 'react'
import { useHistory } from 'react-router-dom';
import { Breadcrumb, Form, Tab, Tabs, Alert, Button } from "react-bootstrap";
import { getConsultorAdapterSchemaMisDatos } from "../../../adapters/consultor.adapter";
import { AuthContext } from "../../../auth/AuthContext";
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { InputSelect, InputText } from '../../../components/Inputs';
import InputImage from '../../../components/Inputs/InputImage';
import { Loader } from '../../../components/Loader/Loader';
import { ModalCropImage } from "../../../components/ModalCropImage/ModalCropImage";
import { IconHelp } from '../../../components/Modals/IconHelp';
import { ModalHelpDownFirma } from "../../../components/Modals/ModalsHelp/ModalHelpDownFirma";
import { ModalHelpUpFirma } from "../../../components/Modals/ModalsHelp/ModalHelpUpFirma";
import { ModalStatus } from "../../../components/ModalStatusServer/ModalStatus";
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import { isAdmin, isVerificador } from '../../../helpers/helpers';
import { useAxios } from "../../../hooks/useAxios";
import { useForm } from "../../../hooks/useForm";
import { useValidator } from "../../../hooks/useValidator";
import { GetConsultorJSON, PostConsultorJSON } from "../../../services/consultor.service";
import { GetPaisJSON } from '../../../services/pais.service';
import jwt from 'jwt-decode' // import dependency
import { types } from '../../../types/types';
import { ModalStatusServer } from '../../../components/ModalStatusServer/ModalStatusServer'
import { ListMenu } from "../../../components/ListMenu/ListMenu";
import {ModalBajaAlta} from './ModalBajaAlta'
import { useModalData } from '../../../hooks/useModalData';

export const Consultor = ({ match, fetchConsultorData, fetchConsultor }) => {

    const { user: { perfil } } = useContext(AuthContext);

    const { dispatch } = useContext(AuthContext);

    const inputFileRef = useRef();

    const [dataModal_Status, handleOpenModal_Status, handleCloseModal_Status] = useModalData()

    //const [fetchConsultor, fetchConsultorData, resetFetchConsultor] = GetConsultorJSON()

    const [fetchIdiomas, fetchIdiomasData] = useAxios('get', `/idiomas`)

    const [fetchLoginConsultor, fetchLoginConsultorData] = useAxios('get', `admin/auth/consultor`)

    const handleSubmitIngresoConsultor = () => {
        fetchLoginConsultor({
            id:formConsultor?.usuario
        })
    }

    useEffect(() => {
       if(fetchLoginConsultorData?.data?.data?.data?.token){
            localStorage.clear();
            const token = fetchLoginConsultorData?.data?.data?.data?.token;
            const lastPath = localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_lastPath') || '/';
            const lang = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE))?.lang || process.env.REACT_APP_LANGUAGE_DEFAULT;
            const data = jwt(token)
            dispatch({
                type: types.login,
                payload: {
                    ...data,
                    token: token,
                },
                lang
            });
       }  
    },[fetchLoginConsultorData.data])


    /*useEffect(() => {

        fetchConsultor({
            params: {
                consultor_nif: match.params.id
            }
        })
        fetchIdiomas()

    }, [])*/

    useEffect(() => {

        if (fetchConsultorData.status === 200) {

            handleFormChangeConsultor(fetchConsultorData.data)
        }

    }, [fetchConsultorData])



    const [fetchRegistroConsultor, fetchRegistroConsultorData] = PostConsultorJSON()

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);

    useEffect(() => {

        console.log(fetchRegistroConsultorData)

        if (fetchRegistroConsultorData.status === 201) {
            // handleCloseModalRegistroConsultor()
            setShowSuccessEdit(true)
            setTimeout(()=>{
               window.location.reload(); 
            }, 500);
            // setShowStatusVerify2(true)
        }

    }, [fetchRegistroConsultorData])

    const [
        formConsultor,
        handleInputChangeConsultor,
        handleFormChangeConsultor,
        handleResetConsultor
    ] = useForm(getConsultorAdapterSchemaMisDatos())


    const [listIdiomasFirmas, setListIdiomasFirmas] = useState([])

    useEffect(() => {

        if (fetchIdiomasData.data && formConsultor.id) {

            if (fetchIdiomasData.data?.status === 200) {
                const listTemp = fetchIdiomasData?.data?.data?.data?.map(item => {
                    return {
                        idioma_id: item.id,
                        nombre: `${item.idioma_nombre}`
                    }
                })

                for (let i = 0; i < listTemp.length; i++) {

                    const itemExtraido = formConsultor.verificador_casillas?.find(item => item.idioma_id === listTemp[i].idioma_id) || {}
                    console.log('itemExtraido', itemExtraido)

                    listTemp[i]['casilla_pagina_firma'] = itemExtraido.casilla_pagina_firma || ''
                    listTemp[i]['casilla_ultima_pagina'] = itemExtraido.casilla_ultima_pagina || ''
                }
                console.log('itemExtraido', listTemp)
                setListIdiomasFirmas(listTemp)
            }
        }

    }, [fetchIdiomasData, formConsultor.id])

    const [valuesValidRegistroConsultor, handleVerifiedValuesRegisterExterno, handleResetValuesRegisterExterno] = useValidator({
        email: { isOk: true, msgError: [`El correo es obligatorio.`] },
        nif: { isOk: true, msgError: [`El NIF es obligatorio.`] },
        nombre: { isOk: true, msgError: [`El nombre es obligatorio.`] },
        apellidos: { isOk: true, msgError: [`Los apellidos son obligatorios.`] },
        pais: { isOk: true, msgError: [`El país es obligatorio.`] },
        codigo_postal: { isOk: true, msgError: [`El código postal es obligatorio.`] },
        poblacion: { isOk: true, msgError: [`La población es obligatoria.`] },
        direccion: { isOk: true, msgError: [`La dirección es obligatoria.`] },
    })

    const handleSubmitFormConsultor = (e) => {

        e.preventDefault()

        if (handleVerifiedValuesRegisterExterno(formConsultor)) {

            const sendConsultor = { ...formConsultor }

            if (formConsultor.es_verificador) {

                sendConsultor.verificador_casillas = JSON.stringify(listIdiomasFirmas)
                // sendConsultor.verificador_casillas = {
                //     '1': {},
                //     '2': {},
                // }

                // .map(function(obj) {
                //     return Object.keys(obj).sort().map(function(key) { 
                //       return obj[key];
                //     });
                //   });

                console.log(sendConsultor.verificador_casillas)

                // for (let i = 0; i < sendConsultor.verificador_casillas?.length; i++) {
                //     sendConsultor.verificador_casillas[i] = new Map(sendConsultor.verificador_casillas[i].map(x => [x.id, x]))
                // }
            } else {
                sendConsultor.verificador_casillas = []

            }

            fetchRegistroConsultor({ body: sendConsultor })
        }
    }

    const [fetchGetImage, fetchGetImageData] = useAxios('get', `/consultor-logo-img`, '', 'blob')

    useEffect(() => {

        if (formConsultor.logo && typeof formConsultor.logo === 'string') {
            console.log(formConsultor)

            fetchGetImage({
                id: `${formConsultor.id}/img?token_img=${formConsultor.logo}`
            })
        }

    }, [formConsultor.logo])

    useEffect(() => {

        if (fetchGetImageData.data) {

            console.log(fetchGetImageData.data.data)
            setInputFiles([new File([fetchGetImageData.data?.data], 'logo.png')])

        }

    }, [fetchGetImageData])

    useEffect(() => {
        console.log(formConsultor)

    }, [formConsultor])

    // Recortar imagen

    const [InputFiles, setInputFiles] = useState([])

    useEffect(() => {
        
        handleFormChangeConsultor({
            ...formConsultor,
            logo: InputFiles?.[0],
        })

    }, [InputFiles])

    // Modal

    const [showModalCropImage, setShowModalCropImage] = useState({
        value: false,
        file: null
    });

    const handleCloseModalCropImage = () => setShowModalCropImage({
        value: false,
        file: null
    })
    const handleShowModalCropImage = (file) => setShowModalCropImage({
        value: true,
        file
    });

    const handleChangeInputImage = (e) => {
        if (e.target.files.length > 0) {
            handleShowModalCropImage(e.target.files[0])
        }
    }


    // Poblaciones

    const [fetchPoblaciones, fetchPoblacionesData] = useAxios('get', `/poblacion`)

    const [poblaciones, setPoblaciones] = useState([]);

    useEffect(() => {

        if (activeEspana) {

            if (formConsultor.codigo_postal?.length === 5) {

                fetchPoblaciones({
                    params: {
                        cp: formConsultor.codigo_postal
                    }
                })
            } else {
                setPoblaciones([])
                handleFormChangeConsultor({
                    ...formConsultor,
                    provincia: '',
                    poblacion: '',
                    // pais: '',
                })
            }
        }

    }, [formConsultor.codigo_postal])

    useEffect(() => {

        console.log(fetchPoblacionesData)

        if (fetchPoblacionesData.data) {

            if (fetchPoblacionesData.data?.status === 201) {

                const poblaciones = fetchPoblacionesData.data?.data?.data

                let poblacionesTemp = poblaciones.map(item => {
                    return ({
                        id: item.poblacion,
                        nombre: item.poblacion
                    })
                })

                setPoblaciones(poblacionesTemp)

            } else {
                handleFormChangeConsultor({
                    ...formConsultor,
                    provincia: '',
                    poblacion: '',
                    // pais: '',
                })
            }

        }

        if (fetchPoblacionesData.error) {
            handleFormChangeConsultor({
                ...formConsultor,
                provincia: '',
                poblacion: '',
                // pais: '',
            })
        }

    }, [fetchPoblacionesData])

    useEffect(() => {

        if (formConsultor.poblacion && fetchPoblacionesData.data) {

            const poblacionesTemp = [...fetchPoblacionesData.data.data.data]

            console.log(poblacionesTemp)

            const resp = poblacionesTemp.find(item => item.poblacion === formConsultor.poblacion)

            handleFormChangeConsultor({
                ...formConsultor,
                provincia: resp?.provincia,
                // pais: resp?.pais,
            })
        }

    }, [formConsultor.poblacion, fetchPoblacionesData.data])

    const [showModalHelpUpFirma, setShowModalHelpUpFirma] = useState(false);
    const [showModalHelpDownFirma, setShowModalHelpDownFirma] = useState(false);

    const handleChangeVerificador = (e) => {
        handleFormChangeConsultor({
            ...formConsultor,
            es_verificador: e.target.checked ? true : false
        })
        // setshowInputFileEMACS(e.target.checked ? true : false)
    }

    const handleChangeRema = (e) => {
        handleFormChangeConsultor({
            ...formConsultor,
            es_comisionista: e.target.checked ? true : false
        })
        // setshowInputFileEMACS(e.target.checked ? true : false)
    }


    // funcionalidad de pais

    const [fetchPaises, fetchPaisesData] = GetPaisJSON()

    useEffect(() => {
        fetchPaises()
    }, [])


    const [activeEspana, setactiveEspana] = useState(false)

    useEffect(() => {

        if (formConsultor.pais === '1' || formConsultor.pais === 1) {

            setactiveEspana(true)
            fetchPoblaciones({
                params: {
                    cp: formConsultor.codigo_postal
                }
            })
        } else {
            setactiveEspana(false)
            handleFormChangeConsultor({
                ...formConsultor,
            })
        }

    }, [formConsultor.pais])

    // Activar usuario

    const [fetchActivarUsuario, fetchActivarUsuarioData] = useAxios('post', `/usuario-admin/${formConsultor.usuario}/activar-usuario`)

    useEffect(() => {

        if (fetchActivarUsuarioData.data?.status === 201) {
            setShowSuccessEdit(true)
            fetchConsultor({
                params: {
                    consultor_nif: match.params.id
                }
            })
        }

    }, [fetchActivarUsuarioData])

    const [fetchDeleteConsultor, fetchDeleteConsultorData] = useAxios('delete', `consultores`)
    const handleDeleteConsultor = (e) => {
        if(window.confirm('Realmente desea borrar el consultor?')){
            fetchDeleteConsultor({
                id: formConsultor.id + '/eliminar',
            })
        }
    }

    const history = useHistory();
    useEffect(() => {
        if(fetchDeleteConsultorData.data?.status === 200){
            history.push(`/consultores`)
        }
    }, [fetchDeleteConsultorData.data])

    const [iDactive, setiDactive] = useState('datosGenerales');
    const listMenu = {
        datosGenerales: {
            title: 'Datos generales'
        },
        comisiones: {
            title: 'Comisiones'
        },
    }
    return (
        <>
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
                {
                    fetchConsultorData.loading ?
                        <div className="py-5">
                            <Loader />
                        </div> : <>

                            <div className="row pt-lg-3 pb-lg-4 px-lg-3">
                                <div className="col-12">
                                    <div className="d-flex mb-4 justify-content-between flex-column flex-lg-row">
                                        <h5 className=''>Datos personales</h5>
                                        {
                                            !formConsultor.estaActivo &&
                                            <ButtonSpinner
                                                variant="danger" type="submit" className=""
                                                handleSubmit={fetchActivarUsuario} fetchDataLoading={[fetchActivarUsuarioData.loading]}
                                                value='Activar' icon={<FontAwesomeIcon icon={faCheck} className='me-1' />}
                                            />
                                        }

                                    </div>
                                </div>

                                {!fetchConsultorData.data?.fecha_baja &&
                                    <Alert variant="danger">
                                      <Alert.Heading>Baja de Consultor</Alert.Heading>
                                      <p>
                                        Al dar de baja a un usuario, este perderá acceso a su cuenta y
                                        toda su información se mantendrá en el sistema, pero no podrá
                                        iniciar sesión.
                                      </p>
                                      <Button variant="danger" onClick={() => handleOpenModal_Status({
                                                                    action: 'BAJA_USUARIO',
                                                                    id:fetchConsultorData.data?.usuario
                                                                })}>
                                        Dar de baja al consultor
                                      </Button>
                                    </Alert>
                                }

                                {fetchConsultorData.data?.fecha_baja &&
                                    <Alert variant="warning">
                                      <Alert.Heading>Alta de Consultor</Alert.Heading>
                                      <p>
                                        Al dar de alta a un usuario, este podrá acceder a su cuenta y
                                        recuperar la información que se mantuvo en el sistema durante su baja.
                                      </p>
                                      <Button variant="success" onClick={() => handleOpenModal_Status({
                                                                    action: 'ALTA_USUARIO',
                                                                    id:fetchConsultorData.data?.usuario
                                                                })}>
                                        Dar de alta al consultor
                                      </Button>
                                    </Alert>
                                 }

                                <Form.Group className="mb-3 col-sm-7 col-lg-3">
                                    <Form.Label className='fw-500'>NIF:</Form.Label>
                                    <p className='mt-0 mb-0'>{formConsultor.nif}</p>
                                </Form.Group>

                                {
                                    isAdmin(perfil) ? (
                                        <Form.Group className="mb-3 col-lg-4">
                                            <InputText
                                                label='Nombre *'
                                                className=''
                                                name='nombre'
                                                value={formConsultor.nombre}
                                                onChange={handleInputChangeConsultor}
                                                validation={valuesValidRegistroConsultor}
                                                fetchData={fetchRegistroConsultorData}
                                            />
                                        </Form.Group>)
                                        : (
                                            <Form.Group className="mb-3 col-lg-4">
                                                <Form.Label className='fw-500'>Nombre:</Form.Label>
                                                <p className='mt-0 mb-0'>{formConsultor.nombre}</p>
                                            </Form.Group>

                                        )
                                }

                                {
                                    isAdmin(perfil) ? (
                                        <Form.Group className="mb-3 col-lg-5">
                                            <InputText
                                                label='Apellidos *'
                                                className=''
                                                name='apellidos'
                                                value={formConsultor.apellidos}
                                                onChange={handleInputChangeConsultor}
                                                validation={valuesValidRegistroConsultor}
                                                fetchData={fetchRegistroConsultorData}
                                            />
                                        </Form.Group>
                                    ) : (
                                        <Form.Group className="mb-3 col-lg-4">
                                            <Form.Label className='fw-500'>Apellidos:</Form.Label>
                                            <p className='mt-0 mb-0'>{formConsultor.apellidos}</p>
                                        </Form.Group>
                                    )
                                }

                                <div className="col-12">
                                    <h5 className='mb-3 mt-2'>Datos de empresa</h5>
                                </div>

                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="row">
                                                <Form.Group className="mb-3 col-12">
                                                    <InputText
                                                        label='Nombre de la empresa'
                                                        className=''
                                                        name='empresa_nombre'
                                                        value={formConsultor.empresa_nombre}
                                                        onChange={handleInputChangeConsultor}
                                                        validation={valuesValidRegistroConsultor}
                                                        fetchData={fetchRegistroConsultorData}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-lg-12">
                                                    <InputText
                                                        label='Dirección *'
                                                        className=''
                                                        name='direccion'
                                                        value={formConsultor.direccion}
                                                        onChange={handleInputChangeConsultor}
                                                        validation={valuesValidRegistroConsultor}
                                                        fetchData={fetchRegistroConsultorData}
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-sm-6 col-lg-5">
                                                    <InputSelect
                                                        label='País *'
                                                        className=''
                                                        name='pais'
                                                        // values={[]}
                                                        values={fetchPaisesData.data || []}
                                                        value={formConsultor.pais}
                                                        onChange={handleInputChangeConsultor}
                                                        validation={valuesValidRegistroConsultor}
                                                        fetchData={fetchRegistroConsultorData}
                                                    // disabled={!fetchPoblacionesData.data || formConsultor.codigo_postal?.length !== 5} 
                                                    />
                                                </Form.Group>

                                                <Form.Group className="mb-3 col-sm-6 col-lg-4">
                                                    <InputText
                                                        label='Código Postal *'
                                                        className=''
                                                        name='codigo_postal'
                                                        value={formConsultor.codigo_postal}
                                                        onChange={handleInputChangeConsultor}
                                                        validation={valuesValidRegistroConsultor}
                                                        fetchData={fetchRegistroConsultorData}
                                                    />
                                                </Form.Group>

                                                {
                                                    activeEspana ? <>
                                                        <Form.Group className="mb-3 col-sm-6 col-lg-8">
                                                            <InputSelect
                                                                label='Población *'
                                                                className=''
                                                                name='poblacion'
                                                                // values={[]}
                                                                values={poblaciones}
                                                                value={formConsultor.poblacion}
                                                                onChange={handleInputChangeConsultor}
                                                                validation={valuesValidRegistroConsultor}
                                                                fetchData={fetchRegistroConsultorData}
                                                                disabled={!fetchPoblacionesData.data || formConsultor.codigo_postal?.length !== 5}
                                                            />

                                                        </Form.Group>
                                                    </> : <>
                                                        <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                            <InputText
                                                                label='Región *'
                                                                className=''
                                                                name='poblacion'
                                                                value={formConsultor.poblacion}
                                                                onChange={handleInputChangeConsultor}
                                                                validation={valuesValidRegistroConsultor}
                                                                fetchData={fetchRegistroConsultorData}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3 col-sm-6 col-lg-6">

                                                            <InputText
                                                                label='Ciudad *'
                                                                className=''
                                                                name='provincia'
                                                                value={formConsultor.provincia}
                                                                onChange={handleInputChangeConsultor}
                                                                validation={valuesValidRegistroConsultor}
                                                                fetchData={fetchRegistroConsultorData}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-sm-9 col-lg-12">
                                                    <InputImage
                                                        label='Logotipo'
                                                        width='100%'
                                                        height='13rem'
                                                        onChange={handleChangeInputImage}
                                                        InputFiles={InputFiles}
                                                        setInputFiles={setInputFiles}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-12">
                                    <h5 className='mb-3 mt-2'>Datos de contacto</h5>
                                </div>

                                <Form.Group className="mb-3 col-sm-7 col-lg-4">
                                    <InputText
                                        label='Teléfono *'
                                        className=''
                                        name='movil'
                                        value={formConsultor.movil}
                                        onChange={handleInputChangeConsultor}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Dirección electrónica *'
                                        className=''
                                        name='email'
                                        value={formConsultor.email}
                                        onChange={handleInputChangeConsultor}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 col-lg-6">
                                    <InputText
                                        label='Web:'
                                        className=''
                                        name='consultor_web'
                                        value={formConsultor.consultor_web}
                                        onChange={handleInputChangeConsultor}
                                        validation={valuesValidRegistroConsultor}
                                        fetchData={fetchRegistroConsultorData}
                                    />
                                </Form.Group>

                                <div className="col-12 mt-2 text-center">
                                    <MsgInputServer fetchData={fetchRegistroConsultorData} className='mt-4' />
                                </div>

                                <div className="col-12 mt-2 pb-4" key='emacs'>
                                    <Form.Check
                                        type='checkbox'
                                        name='es_verificador'
                                        // value={formConsultor.es_verificador}
                                        onChange={handleChangeVerificador}
                                        checked={formConsultor.es_verificador}
                                        id='emacs'
                                        className='mb-0 fw-500'
                                        style={{ 'fontSize': '1.2rem' }}
                                        label={
                                            <>
                                                <div>Verificador</div>
                                            </>
                                        }
                                    />
                                    {/* <MsgInput obj={valuesValidRegistroEmpresa} name='emacs' /> */}

                                </div>
                                 <div className="col-12 mt-2 pb-4" key='es_comisionista'>
                                     <Form.Check
                                        type='checkbox'
                                        name='es_comisionista'
                                        //value={formConsultor.es_consultor_rema}
                                        checked={formConsultor.es_comisionista}
                                        onChange={handleChangeRema}
                                        id={`default-checkbox`}
                                        className="mb-0 fw-500"
                                        style={{ 'fontSize': '1.2rem' }}
                                        label={
                                            <>
                                                <div>Comisionista</div>
                                            </>
                                        }
                                    />
                               </div>

                            </div>
                            <div className=' pt-3 d-flex justify-content-end border-top'>
                                  {
                                    isAdmin(perfil) &&
                                    <ButtonSpinner
                                        variant="danger" type="submit" className="me-2"
                                        handleSubmit={handleDeleteConsultor} fetchDataLoading={[fetchDeleteConsultorData.loading]}
                                        value='Eliminar' icon={<FontAwesomeIcon icon={faTrash} className='me-1' />}
                                    /> 
                                   }      

                                <ButtonSpinner
                                    variant="default" type="submit" className="me-2"
                                    handleSubmit={handleSubmitIngresoConsultor} fetchDataLoading={[fetchLoginConsultorData.loading]}
                                    value='Iniciar sesión' icon={<FontAwesomeIcon icon={faUser} className='me-1' />}
                                /> 
                                   

                                <ButtonSpinner
                                    variant="info" type="submit" className="me-2"
                                    handleSubmit={handleSubmitFormConsultor} fetchDataLoading={[fetchRegistroConsultorData.loading]}
                                    value='Guardar' icon={<FontAwesomeIcon icon={faFile} className='me-1' />}
                                />
                            </div>
                        </>
                }
             </div>   

            <ModalCropImage
                show={showModalCropImage}
                handleClose={handleCloseModalCropImage}
                height='500px'
                setInputFiles={setInputFiles}
                escalas={[
                    { x: 1, y: 1, label: 'Cuadrado' },
                    { x: 4, y: 2, label: 'Rectángulo' }
                ]}
            />
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusServer
                fetchData={fetchDeleteConsultorData}
            />
            <ModalHelpUpFirma setShowModalHelp={setShowModalHelpUpFirma} showModalHelp={showModalHelpUpFirma} />

            <ModalHelpDownFirma setShowModalHelp={setShowModalHelpDownFirma} showModalHelp={showModalHelpDownFirma} />

            <ModalBajaAlta
                data={dataModal_Status}
                handleCloseModal={handleCloseModal_Status}
                nif={formConsultor.nif}
                history={history}
            />
        </>

    )
}
