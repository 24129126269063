import React from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faSort, faEdit, faDownload, faPlane, faUniversity} from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components/Loader/Loader';
import { PaginationT } from '../../../components/PaginationT/PaginationT';

import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext';
import { isModuleOrganizacion } from '../../../helpers/helpers';
import { ModalTarifa } from './ModalTarifa';
import { useModalData } from '../../../hooks/useModalData';
import { ModalDatosBancarios } from './ModalDatosBancarios'

export const TarifasMain = ({ fetchTarifasData, formValuesSearch, namePage = 'default', handleFormChange, fetchTarifas, activeLink }) => {
    const history = useHistory();
    const Lang = useLang();
    const [dataModal_Tarifa, handleOpenModal_Tarifa, handleCloseModal_Tarifa] = useModalData()
    const [dataModal_Bancario, handleOpenModal_Bancario, handleCloseModal_Bancario] = useModalData()
    return (
        <>
            <div className="col">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">

                    <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                        <div>
                            <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} Tarifas</h5>
                        </div>
                        <div className='d-flex justify-content-end ms-auto'>
                            <Button variant="warning"  onClick={() => {
                                handleOpenModal_Bancario({
                                    action: 'UPDATE'
                                })
                            }}><FontAwesomeIcon icon={faUniversity} className='me-2' /> Datos Bancarios</Button>

                            <Button variant="primary" className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => {
                                    handleOpenModal_Tarifa({
                                        action: 'CREATE'
                                    })
                                }}
                            >
                                <FontAwesomeIcon icon={faPlane} className='me-2' /> <span className=''>&nbsp;Nueva tarifa</span>
                            </Button>
                        </div>
                    </div>
                    {
                        fetchTarifasData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchTarifasData?.data?.total ?

                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr className="text-center">
                                                        <th className='text-nowrap'>Descripción</th>
                                                        <th className='text-nowrap'>Estado</th>
                                                        <th className='text-nowrap text-center'>Codígo contable</th>
                                                        <th className='text-nowrap  text-center'>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchTarifasData?.data?.items?.map((item) => {
                                                        return (
                                                            <tr key={item.id} className="text-center">
                                                                <td valign='middle'>{item.descripcion}</td>
                                                                <td valign='middle'>{item.estado ? 'Activo' : 'Inactivo'}</td>
                                                                <td valign='middle'>{item.codigo_contable}</td>
                                                                <td valign='middle' className='text-center'>
                                                                    <Button variant="transparent" size='sm' className='ms-3 my-0 text-primary'
                                                                        onClick={
                                                                            () => handleOpenModal_Tarifa({
                                                                                action: 'EDIT',
                                                                                id: item.id,
                                                                            })
                                                                        }
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit} />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchTarifasData?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchTarifasData?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                                }
                            </>
                    }
                </div>
            </div>
            <ModalTarifa
                data={dataModal_Tarifa}
                handleCloseModal={handleCloseModal_Tarifa}
                fetchTarifas={fetchTarifas}
                activeLink={activeLink}
                handleFormChange={handleFormChange}
            />

            <ModalDatosBancarios
                data={dataModal_Bancario}
                handleCloseModal={handleCloseModal_Bancario}
            />
        </>
    )
}
