
// --------------- listado de Organizaciones

export const organizacionesAdapterSchema = (item = {}) => {

    const data = {
        id: item.id || '',
        cif: item.org_cif || '',
        nombre: item.org_nombre || '',
        fecha_alta: item.org_fecha_alta || '',
        telefono: item.org_telefono || '',
        org_id: item.org_id || '',
        org_convenio: item.org_convenio || '',
        withIntegracionApi : item.integracion_api ? 'Si' : 'No'
    }

    return {
        ...data,
        fecha_alta: data.fecha_alta?.split(' ')?.[0] || '',
        id: item['org_id']
    }
}

export const organizacionesAdapter = (data) => {

    const dataTemp = data?.data?.data

    const items = dataTemp?.data?.map(item => (organizacionesAdapterSchema(item)))

    return {
        data: {
            total: dataTemp?.total,
            per_page: dataTemp?.per_page,
            items
        },
        status: data?.status
    }
}

// --------------- Objeto GET organizacion


export const getOrganizacionAdapterSchema = (item = {}) => {

    const data = {
        id: item.id || '',
        usuario: item.org_cif || '',
        nombre: item.org_nombre || '',
        direccion: item.org_direccion || '',
        codigo_postal: item.org_codigo_postal || '',
        poblacion: item.org_poblacion || '',
        provincia: item.org_provincia || '',
        logo: item.org_logo || '',
        telefono: item.org_telefono || '',
        email: item.org_email || '',
        web: item.org_web || '',
        persona_contacto_cargo: item.org_persona_contacto_cargo || '',
        persona_contacto_email : item.org_persona_contacto_email || '',
        persona_contacto_nombre: item.org_persona_contacto_nombre || '',
        persona_contacto_telefono: item.org_persona_contacto_telefono || '',
        contacto_facturacion_email: item.org_contacto_facturacion_email || '',
        contacto_facturacion_nombre: item.org_contacto_facturacion_nombre || '',
        contacto_facturacion_telefono: item.org_contacto_facturacion_telefono || '',
        org_convenio: item.org_convenio || '',
        org_tipo: item.org_tipo || '',
        org_tamanio: item.org_tamanio || '',
        exento_cuota: item.exento_cuota || false,
        comisionista_id: item.comisionista_id || '',
        tipo_comision: item.tipo_comision || '',
        org_facturar_dapcons_convenio: item.org_facturar_dapcons_convenio || false,
        org_fecha_alta: item.org_fecha_alta || '',
        alerta_pago: item.alerta_pago || '',
    }

    return {
        ...data,
        id: data.usuario
    }
}

export const getOrganizacionAdapter = (data) => {

    const dataTemp = data?.data?.data

    // console.log(dataTemp)

    // const items = dataTemp?.data?.map(item => (getOrganizacionAdapterSchema(item)))
    const item = getOrganizacionAdapterSchema(dataTemp)

    return {
        data: item,
        status: data?.status
    }
}


// ----------------------- Mis Datos ------------------------

export const getOrganizacionAdapterSchemaMisDatos = (item = {}) => {

    const data = {
        id: item.org_id || '',
        cif: item.org_cif || '',
        nombre: item.org_nombre || '',
        direccion: item.org_direccion || '',
        codigo_postal: item.org_codigo_postal || '',
        poblacion: item.org_poblacion || '',
        pais: item.org_pais || '',
        provincia: item.org_provincia || '',
        logo: item.org_logo || '',
        telefono: item.org_telefono || '',
        email: item.org_email || '',
        web: item.org_web || '',
        persona_contacto_cargo: item.org_persona_contacto_cargo || '',
        persona_contacto_email : item.org_persona_contacto_email || '',
        persona_contacto_nombre: item.org_persona_contacto_nombre || '',
        persona_contacto_telefono: item.org_persona_contacto_telefono || '',
        contacto_facturacion_email: item.org_contacto_facturacion_email || '',
        contacto_facturacion_nombre: item.org_contacto_facturacion_nombre || '',
        contacto_facturacion_telefono: item.org_contacto_facturacion_telefono || '',
        sgm_emacs: item.doc_sgm_emacs || '',
        sgm_iso14001: item.doc_sgm_iso14001 || '',
        estaActivo: item.usuario?.activo || false,
        usuario: item.usuario_id || '',
        org_fecha_alta: item.org_fecha_alta || '',
        org_fecha_baja: item.org_fecha_baja || '',
        integracion_api: item.integracion_api || false,
        exento_cuota: item.exento_cuota || false,
        org_convenio: item.org_convenio || '',
        org_tipo: item.org_tipo || '',
        org_tamanio: item.org_tamanio || '',
        comisionista_id: item.comisionista_id || '',
        tipo_comision: item.tipo_comision || '',
        comisionista: item.comisionista || '',
        tipo_comision: item.tipo_comision || '',
        org_facturar_dapcons_convenio: item.org_facturar_dapcons_convenio || false,
        org_fecha_alta: item.org_fecha_alta || '',
        alerta_pago: item.alerta_pago || '',
    }

    return {
        ...data
    }
}

export const getOrganizacionAdapterMisDatos = (data) => {

    const dataTemp = data?.data?.data

    // console.log(dataTemp)

    // const items = dataTemp?.data?.map(item => (getOrganizacionAdapterSchema(item)))
    const item = getOrganizacionAdapterSchemaMisDatos(dataTemp)

    return {
        data: item,
        status: data?.status
    }
}

export const postOrganizacionAdapterSchemaMisDatos = (item = {}) => {

    const data = {
        org_id: item.id || '',
        org_cif: item.cif || '',
        org_codigo_postal: item.codigo_postal || '',
        org_contacto_facturacion_email: item.contacto_facturacion_email || '',
        org_contacto_facturacion_nombre: item.contacto_facturacion_nombre || '',
        org_contacto_facturacion_telefono: item.contacto_facturacion_telefono || '',
        org_direccion: item.direccion || '',
        org_email: item.email || '',
        org_logo: item.logo || '',
        org_nombre: item.nombre || '',
        org_persona_contacto_cargo: item.persona_contacto_cargo || '',
        org_persona_contacto_email: item.persona_contacto_email || '',
        org_persona_contacto_nombre: item.persona_contacto_nombre || '',
        org_persona_contacto_telefono: item.persona_contacto_telefono || '',
        org_poblacion: item.poblacion || '',
        org_provincia: item.provincia || '',
        org_telefono: item.telefono || '',
        org_web: item.web || '',
        usuario_id: item.usuario || '',
        doc_sgm_emacs: item.sgm_emacs || '',
        doc_sgm_iso14001: item.sgm_iso14001 || '',
        org_pais: item.pais || '',
        integracion_api: item.integracion_api || false,
        org_convenio: item.org_convenio || '',
        org_tipo: item.org_tipo || '',
        org_tamanio: item.org_tamanio || '',
        exento_cuota: item.exento_cuota || false,
        comisionista_id: item.comisionista_id || '',
        tipo_comision: item.tipo_comision || '',
        org_facturar_dapcons_convenio: item.org_facturar_dapcons_convenio || false,
        org_fecha_alta: item.org_fecha_alta || '',
    }

    return data
}


export const postOrganizacionAdapterMisDatos = (form) => {

    const formAdapter = postOrganizacionAdapterSchemaMisDatos(form)

    let formData = new FormData();

    for (const key in formAdapter) {
        formData.append(key, formAdapter[key])
    }

    return formData
}
