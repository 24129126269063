import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faFile, faSort } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components/Loader/Loader';
import { PaginationT } from '../../../components/PaginationT/PaginationT';

import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import { ModalPagoCuota } from './ModalPagoCuota';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';

export const UsuariosMain = ({ fetchCuotasAnualesData, formValuesSearch, namePage = 'default', handleFormChange,
    modulo_app, estaVerificando, fetchCuotasAnuales }) => {

    console.log('fetchCuotasAnualesData', fetchCuotasAnualesData)
    const history = useHistory();

    const Lang = useLang();

    // Crear Consultores basico

    const [showModalPagoCuota, setShowModalPagoCuota] = useState({});

    const handleCloseModalPagoCuota = () => setShowModalPagoCuota({
        value: false,
        payload: {}
    })
    const handleShowModalPagoCuota = (payload = {}) => setShowModalPagoCuota({
        value: true,
        payload
    });

    useEffect(() => {
        // fetchRCP()
        // fetchVerificadores()
        // fetchIdiomas()
    }, [])

    return (
        <>
            <div className="col">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">

                    <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                        <div>
                            <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} {namePage}</h5>
                        </div>
                        <div className='d-flex justify-content-end ms-auto'>
                            <Button variant="primary" className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => handleShowModalPagoCuota({
                                    action: 'CREATE'
                                })}
                            >
                                <FontAwesomeIcon icon={faFile} className='me-2' /> Alta <span className=''>&nbsp;Pagos</span>
                            </Button>
                        </div>
                    </div>

                    {
                        fetchCuotasAnualesData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchCuotasAnualesData?.data?.data?.data?.total ?

                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-nowrap'>Organización</th>

                                                        <th className='text-nowrap text-center'>
                                                            Año cuota
                                                        </th>
                                                        <th className='text-nowrap text-center'>
                                                            Fecha de pago
                                                        </th>
                                                        {/* <th className='text-nowrap text-center'>Es verificador</th> */}
                                                        {/* <th className='text-nowrap text-center'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={Lang('FECHA_ESTADO')}
                                                                id='fecha_estado'
                                                            />
                                                        </th> */}
                                                        <th className='text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchCuotasAnualesData?.data?.data?.data?.data?.map((item) => {
                                                        // console.log(`/consultores/${item.id}`)
                                                        return (
                                                            <tr key={item.id}>
                                                                <td valign='middle'>{item.org_id}</td>
                                                                <td valign='middle' className='text-center'>{item.year}</td>
                                                                <td valign='middle' className='text-nowrap text-center'>{(new Date(item.pagado)?.toLocaleDateString())}</td>
                                                                {/* <td valign='middle' className='text-nowrap text-center'>{item.es_verificador}</td> */}
                                                                <td valign='middle' className='text-center'>
                                                                    <table className='w-100'>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <OverlayTrigger
                                                                                        key={item.id + '1'}
                                                                                        placement={'top'}
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${item.id}`}>
                                                                                                {Lang('VER_MAS')}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <Button variant="transparent" size="sm" className='btn-modalVermas'
                                                                                            onClick={() => handleShowModalPagoCuota({
                                                                                                action: 'EDIT',
                                                                                                item
                                                                                            })}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faBook} />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchCuotasAnualesData?.data?.data?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchCuotasAnualesData?.data?.data?.data?.per_page}
                                            />
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                                }
                            </>
                    }
                </div>
            </div>

            <ModalPagoCuota
                showModalPagoCuota={showModalPagoCuota}
                handleCloseModalPagoCuota={handleCloseModalPagoCuota}
                fetchSuccess={fetchCuotasAnuales}
                formValuesSearch={formValuesSearch}
            />
        </>
    )
}
