import React, { useEffect } from 'react'
import { Table, Breadcrumb } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import './Maintenance.css'
import { useFetch } from '../../hooks/useFetch';
import { Loader } from '../../components/Loader/Loader';
import 'chart.js/auto';

import { Pie } from 'react-chartjs-2';

export const Maintenance = ({ namePage }) => {

    // const [fetchLogsLaravel, fetchLogsLaravelData] = useFetch('GET', `/maintenance/laravel`, null, 'text')

    // const [fetchLogsRequest, fetchLogsRequestData] = useFetch('GET', `/maintenance/requests`, null, 'text')

    useEffect(() => {

        // fetchLogsLaravel({
        //     params: {
        //         n_lines: 100
        //     }
        // })
        // fetchLogsRequest({
        //     params: {
        //         n_lines: 100
        //     }
        // })

    }, [])

    const data = {
        labels: ['Libre', 'Utilizado'],
        datasets: [
          {
            label: '# of Votes',
            data: [100, 50],
            backgroundColor: [
              '#f2f4f6',
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              '#e3e9ef',
              'rgba(54, 162, 235, 1)',
            ],
            borderWidth: 1,
          },
        ],
    }

    const options = {
        plugins: {
            legend: {
                display: false
            },
            tooltips: {
                enabled: false
             }
        }
        
        // tooltips: {
        //     callbacks: {
        //        label: function(tooltipItem) {
        //               return tooltipItem.yLabel;
        //        }
        //     }
        // }
    }

    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {namePage}
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='col-sm-10 col-md-8 col-lg-6 col-xl-4 mb-4'>
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp ">
                    <h5 className='mb-3 mt-0'>Espacio utilizado</h5>
                    <Pie data={data} options={options}/>
                </div>
            </div>

        </div>

    )
}
