import React, { useContext, useEffect, useState } from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCheckCircle, faFile, faFileExcel, faPlug, faPlus, faSort } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components/Loader/Loader';
import { PaginationT } from '../../../components/PaginationT/PaginationT';

import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import { ModalCrearRCPs } from './ModalCrearRCPs';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';
import { useAxios } from '../../../hooks/useAxios';
import { ModalStatus } from '../../../components/ModalStatusServer/ModalStatus';
import { getDataFile } from '../../../helpers/helpers';
import { ModalStatusServer2 } from '../../../components/ModalStatusServer/ModalStatusServer2';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';

export const RCPsMain = ({ fetchRCPsData, fetchRCPs, formValuesSearch, namePage = 'default', handleFormChange, modulo_app, estaVerificando }) => {

    console.log('fetchRCPsData', fetchRCPsData)
    const history = useHistory();

    const Lang = useLang();

    // Crear Organizaciones basico

    const [showModalCrearRCPs, setShowModalCrearRCPs] = useState({
        value: false,
        item: null
    });

    const handleCloseModalCrearRCPs = () => setShowModalCrearRCPs({
        value: false,
        item: null
    })
    const handleShowModalCrearRCPs = (item) => setShowModalCrearRCPs({
        value: true,
        item
    });

    const [fetchRCP, fetchRCPData] = useAxios('get', `/rcp`)
    const [fetchVerificadores, fetchVerificadoresData] = useAxios('get', `/verificadores`)
    const [fetchIdiomas, fetchIdiomasData] = useAxios('get', `/idiomas`)

    useEffect(() => {
        //fetchRCP()
        fetchVerificadores()
        fetchIdiomas()
    }, [])

    const [showSuccessEdit, setShowSuccessEdit] = useState(false);


    // Start --- Handle Click Descargar Excel

    const [fetchRCPsExcel, fetchRCPsExcelData, resetfetchRCPsExcelData]
        = useAxios('GET', `/rcp`, '', 'blob')

    const handleClickDownloadFile = () => {

        let formValuesSearchTemp = { ...formValuesSearch }

        Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

        fetchRCPsExcel({
            params: {
                ...formValuesSearchTemp,
                to_excel: true
            }
        })
    }

    useEffect(() => {

        if (fetchRCPsExcelData.data) {
            getDataFile(fetchRCPsExcelData, resetfetchRCPsExcelData, 'RCPs', 'xlsx')
        }
    }, [fetchRCPsExcelData])

    return (
        <>
            <div className="col">
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">

                    <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                        <div>
                            <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} {namePage}</h5>
                        </div>
                        <div className='d-flex justify-content-end ms-auto'>
                            <ButtonSpinner
                                variant="outline-success" type="submit" className="ms-3 px-3"
                                handleSubmit={handleClickDownloadFile} fetchDataLoading={[fetchRCPsExcelData.loading]}
                                value='Descargar Excel' icon={<FontAwesomeIcon icon={faFileExcel} className='me-2' />}
                            />
                            <Button variant="primary" className="px-3 ms-3 d-flex align-items-center"
                                onClick={() => handleShowModalCrearRCPs({})}
                            >
                                <FontAwesomeIcon icon={faPlus} className='me-2' /> Crear <span className=''>&nbsp;RCP</span>
                            </Button>
                        </div>
                    </div>

                    {
                        fetchRCPsData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    fetchRCPsData?.data ?

                                        <>
                                            <Table hover bordered responsive className='tableMain'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-nowrap'>Equivalencia</th>
                                                        <th className='text-nowrap'>Año</th>
                                                        <th className='text-nowrap' style={{ 'width': '200px' }}>Material</th>
                                                        <th className='text-nowrap'>Nombre</th>
                                                        <th className='text-center'></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {fetchRCPsData?.data.map((item) => {
                                                        return (
                                                            <tr key={item.id} >
                                                                <td valign='middle'>{item.equivalencia}</td>
                                                                <td valign='middle'>{item.anyo}</td>
                                                                <td valign='middle' className='border-bottom-0 px-0' style={{ 'width': '200px' }}>
                                                                    {item.rcp_idiomas[0]?.descripcion}
                                                                </td>

                                                                <td valign='middle' className='border-bottom-0 px-0' >
                                                                    {item.rcp_idiomas[0]?.rcp_nombre}
                                                                </td>

                                                                <td valign='middle' className='text-center'>
                                                                    <table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>
                                                                                    <OverlayTrigger
                                                                                        key={item.id + '1'}
                                                                                        placement={'top'}
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${item.id}`}>
                                                                                                {Lang('VER_MAS')}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <Button variant="transparent" size="sm" className='btn-modalVermas'
                                                                                            onClick={() => handleShowModalCrearRCPs(item)}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faBook} />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </Table>

                                            {/* <PaginationT
                                                total={fetchRCPsData?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchRCPsData?.data?.per_page}
                                            /> */}
                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se ha encontrado resultados</h5>
                                }
                            </>
                    }
                </div>
            </div>

            <ModalCrearRCPs
                showModalCrearRCPs={showModalCrearRCPs}
                handleCloseModalCrearRCPs={handleCloseModalCrearRCPs}
                fetchRCPData={fetchRCPData}
                fetchVerificadoresData={fetchVerificadoresData}
                fetchIdiomasData={fetchIdiomasData}
                fetchRCPs={fetchRCPs}
                setShowSuccessEdit={setShowSuccessEdit}
            />
            <ModalStatus setShowStatus={setShowSuccessEdit} showStatus={showSuccessEdit}>
                <div className="py-0 text-center">
                    <h5 className='mb-4 fw-normal'>
                        <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                    </h5>
                    <p className='mb-0'>Los datos han sido guardados correctamente
                    </p>
                </div>
            </ModalStatus>

            <ModalStatusServer2 fetchData={fetchRCPsExcelData} onlyError />

        </>
    )
}
