
const generateDataFake = (adapterSchema) => {

    const rest = []

    const schema = (adapterSchema).toString();

    let letterCompile = ''
    let splitItems = []
    let arrayKeysServer = []

    if (schema.indexOf('item') !== -1) {

        letterCompile = schema.split('id: ')?.[1].split('.id ||')?.[0]
        splitItems = schema.split(': ' + letterCompile + '.')

        for (let j = 1; j < splitItems.length; j++) {
            arrayKeysServer.push(splitItems[j].split(' ||')?.[0])
        }

    } else {

        letterCompile = schema.split('id:')?.[1].split('.id||')?.[0]
        splitItems = schema.split(':' + letterCompile + '.')

        for (let j = 1; j < splitItems.length; j++) {
            arrayKeysServer.push(splitItems[j].split('||')?.[0])
        }
    }

    // console.log(schema)
    // console.log(letterCompile)
    // console.log(splitItems)
    // console.log(arrayKeysServer)

    for (let i = 0; i < 5; i++) {

        let schemaTemp = {}

        for (let j = 0; j < arrayKeysServer.length; j++) {
            schemaTemp[arrayKeysServer[j]] = dbFake(arrayKeysServer[j], i)
        }

        rest.push(schemaTemp)
    }

    console.log(rest)

    return rest
}

export const generateDataServerFake = (method, url, adapterSchema) => {

    switch (method) {

        case 'get':

            if (url.indexOf('lista_paginada') !== -1) {
                return {
                    data: {
                        data: {
                            data: generateDataFake(adapterSchema),
                            total: 10,
                            per_page: 5
                        }
                    },
                    status: 200
                }
            }

            if (url.indexOf('lista_no_paginada') !== -1) {
                return {
                    data: {
                        data: generateDataFake(adapterSchema)
                    },
                    status: 200
                }
            }

            if (url.indexOf('objeto_unico') !== -1) {
                return {
                    data: {
                        data: generateDataFake(adapterSchema)[0]
                    },
                    status: 200
                }
            }

            return {
                status: 404
            }

        default:
            break;
    }


}

const generateID = (index) => {

    const ids = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    return ids[index]
}

const generateCIF = (index) => {

    const cif = [
        'W7744763I',
        'R3751157C',
        'D44815603',
        'W4113748J',
        'U56890726',
        'E72778921',
        'J49814064',
        'C36103125',
        'R3925145I',
        'V67557884'
    ]

    return cif[index]
}

const generateNombreEmpresa = (index) => {

    const cif = [
        'EXLABESA ARCHITECTURE',
        'AIT Group',
        'Isoltex',
        'Knauf',
        'TITAN',
        'Dott.gallina',
        'Aislux',
        'WOOLBAND',
        'Colorker',
        'Tecnopol',
    ]

    return cif[index]
}

const generateNIF = (index) => {

    const cif = [
        '61062601Q',
        '56539177W',
        '69704981P',
        '02929897L',
        '28216587A',
        '97758679P',
        '00947603A',
        '27006627G',
        '34456195X',
        '64033734D'
    ]

    return cif[index]
}

const generateTELEFONO = (index) => {

    const cif = [
        '730 878 084',
        '761 589 754',
        '763 919 897',
        '759 032 048',
        '653 433 423',
        '774 157 362',
        '615 210 170',
        '675 683 290',
        '602 293 788',
        '752 145 392'
    ]

    return cif[index]
}

const generateFechaAlta = (index) => {

    const cif = [
        '2022-03-08 14:15:38',
        '2022-04-07 14:15:38',
        '2022-04-02 14:15:38',
        '2022-02-11 14:15:38',
        '2022-04-03 14:15:38',
        '2022-03-21 14:15:38',
        '2022-04-04 14:15:38',
        '2022-03-07 14:15:38',
        '2022-01-07 14:15:38',
        '2022-04-03 14:15:38',
    ]

    return cif[index]
}

const generateNombre = (index) => {

    const cif = [
        'Emily',
        'Breanna',
        'Paul',
        'Keith',
        'Monica',
        'Donna',
        'Rhonda',
        'Robert',
        'Brian',
        'Bill',
    ]

    return cif[index]
}

const generateApellidos = (index) => {

    const cif = [
        'Marcial Romero',
        'Grant Solis',
        'Cuevas Garcia',
        'Castillo Rodriguez',
        'Green Smith',
        'Newman Miller',
        'Flores Morrison',
        'Garcia Richmond',
        'Martin Collins',
        'Herrera Gutierrez',
    ]

    return cif[index]
}

const generateBoolean = (index) => {

    const boolean = [
        false,
        false,
        true,
        false,
        false,
        false,
        true,
        true,
        false,
        false
    ]

    return boolean[index]
}

const generatePoblacion = (index) => {

    const boolean = [
        'Poblacion 1',
        'Poblacion 2',
        'Poblacion 3',
        'Poblacion 4',
        'Poblacion 5',
        'Poblacion 6',
        'Poblacion 7',
        'Poblacion 8',
        'Poblacion 9',
        'Poblacion 10',
    ]

    return boolean[index]
}

const generateProvincia = (index) => {

    const boolean = [
        'Provincia 1',
        'Provincia 2',
        'Provincia 3',
        'Provincia 4',
        'Provincia 5',
        'Provincia 6',
        'Provincia 7',
        'Provincia 8',
        'Provincia 9',
        'Provincia 10',
    ]

    return boolean[index]
}

const generateDireccion = (index) => {

    const boolean = [
        'Dirección 1',
        'Dirección 2',
        'Dirección 3',
        'Dirección 4',
        'Dirección 5',
        'Dirección 6',
        'Dirección 7',
        'Dirección 8',
        'Dirección 9',
        'Dirección 10',
    ]

    return boolean[index]
}

const generateCodigoPostal = (index) => {

    const boolean = [
        '08028',
        '08029',
        '08030',
        '08031',
        '08032',
        '08033',
        '08034',
        '08035',
        '08036',
        '08037',
    ]

    return boolean[index]
}

const generateUrlLogo = (index) => {

    const boolean = [
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
        'https://img2.freepng.es/20180526/gia/kisspng-logo-clip-art-logotipos-5b0923de6d4381.3858078515273256624476.jpg',
    ]

    return boolean[index]
}

const generateEmail = (index) => {

    const boolean = [
        'fef20fji@gmail.com',
        'fkio_934f@outlook.com',
        'powe923@gmail.com',
        'fer_34f_efw@outlook.com',
        'ff543423@gmail.com',
        'freig3@gmail.com',
        'bgfrgt_tgr@gmail.com',
        'erfet56@outlook.com',
        'rgoik34r@gmail.com',
        'dsfkreg43@outlook.com',
    ]

    return boolean[index]
}

const dbFake = (key, index) => {

    const ttemp = {
        id: generateID(index),
        org_nif: generateCIF(index),
        org_telefono: generateTELEFONO(index),
        org_fecha_alta: generateFechaAlta(index),
        org_nombre: generateNombreEmpresa(index),
        consultor_nif: generateNIF(index),
        consultor_nombre: generateNombre(index),
        consultor_apellidos: generateApellidos(index),
        consultor_movil: generateTELEFONO(index),
        es_verificador: generateBoolean(index),
        poblacion: generatePoblacion(index),
        usuario: generateNIF(index),
        empresa_nombre: generateNombreEmpresa(index),
        consultor_direccion: generateDireccion(index),
        consultor_codigo_postal: generateCodigoPostal(index),
        consultor_provincia: generateProvincia(index),
        consultor_poblacion: generatePoblacion(index),
        logo: generateUrlLogo(index),
        email: generateEmail(index),
    }

    return ttemp[key]
}
