import React from 'react';

import './AvisLegal.css'

import { NavTopLegal } from '../../components/NavTopLegal/NavTopLegal';
import { useLang } from '../../language';

export const AvisLegal = () => {
    const Lang = useLang();
    return (
        <div className="App bg-opacity-primary">
            <section className="App-header">
            <NavTopLegal />
                <section id="Main-legal">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="Main-legal-content shadow-sm animate__animated animate__fadeInUp">
                                    <h4 className="text-center">Avís legal</h4>
                                    <ol>
                                        <li>
                                            Aquesta pàgina web és titularitat d'Ares S.Coop. (Ares)
                                            <ul>
                                                <li>
                                                    CIF: F82699125
                                                </li>
                                                <li>
                                                    Domicili social: Carrer Covarrubias 22, 3r Dreta, 28010 Madrid
                                                </li>
                                                <li>
                                                    Adreça de correu electrònic: info@arescoop.es
                                                </li>
                                                <li>
                                                    Telèfon: (+34) 91 444 52 71
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Ares rebutja tota responsabilitat que es derivi de la mala utilització dels
                                            continguts exposats
                                            en aquestes pàgines pels usuaris externs a Ares i es reserva el dret a
                                            actualitzar-los,
                                            eliminar-los o establir limitacions i les accions legals corresponents.
                                            Ares no és responsable de l'exactitud i l'actualització de la informació provinent
                                            de tercers
                                            que consti a la pàgina web oa la qual es remeti des de la mateixa. Així mateix no es
                                            fa
                                            responsable dels possibles errors de seguretat que es poguessin produir pel fet
                                            d'utilitzar
                                            ordinadors infectats amb virus informàtics o de les conseqüències que es poguessin
                                            derivar d'el
                                            mal funcionament de navegador.
                                            Ares ofereix aquesta web per facilitar l'accés públic a la informació de què
                                            disposa.
                                            Ares és el propietari de el present lloc web. Queda prohibida la reproducció total o
                                            parcial
                                            dels seus continguts sense l'autorització expressa d'Ares i dels propietaris de les
                                            marques.
                                        </li>
                                        <li>
                                            El present Avís Legal es regeix íntegrament per la legislació espanyola. Per a la
                                            resolució de qualsevol conflicte relatiu a la interpretació o aplicació d'aquest
                                            Avís Legal, l'usuari se sotmet expressament a la jurisdicció dels tribunals en on
                                            estigui domiciliada Ares.
                                        </li>
                                    </ol>

                                    <h5 className="mt-4 mb-4 mb-md-4 fw-bold h5">Condicions d'ús</h5>

                                    <p>
                                        Ares S.Coop. administra les aplicacions, serveis o webs en els dominis * .arescoop.es, * .ecomitia.org, * .trabajaenmadrid.es, * xarxatreaball.cat. A l'utilitzar o accedir a aquests llocs, aplicacions o serveis (el "Lloc"), tu ( "l'Usuari") manifestes que has llegit i comprès i t'obligues a complir les presents Condicions d'Ús (les "Condicions d'Ús" o el "Contracte"), tant si ets membre registrat com si no ho ets.
                                    </p>
                                    <p>
                                        Reservat el dret, a la nostra elecció exclusiva, de canviar, modificar, afegir o eliminar parts de les presents Condicions d'Ús en qualsevol moment i sense previ avís.
                                    </p>
                                    <p>
                                        Si continues utilitzant el Servei o el Lloc un cop introduïdes aquestes modificacions hauràs acceptat implícitament les noves Condicions d'ús. Si no acceptes complir aquestes o les futures condicions d'ús, no utilitzis (ni segueixis utilitzant) el Servei o el Lloc ni accedeixis (ni segueixis accedint) als mateixos.
                                    </p>
                                    <p>
                                        Ets responsable de visitar regularment el Lloc per determinar si s'han modificat les presents Condicions d'Ús i consultar aquestes modificacions.
                                    </p>

                                    <h5 className="mt-4 mb-4 mb-md-4 fw-bold h5">Drets sobre el contingut del Lloc</h5>

                                    <p>
                                        Tot el contingut del Lloc, incloent dissenys, text, gràfics, imatges, vídeo, informació, aplicacions, programari, música, so i altres arxius, així com la seva selecció i disposició (el "Contingut del Lloc"), són propietat exclusiva d'Ares , dels seus usuaris o dels seus proveïdors, amb tots els drets reservats. Cap part d'al Contingut del Lloc podrà ser modificada, copiada, distribuïda, emmarcada, reproduïda, republicada, descarregada, mostrada, publicada, transmesa o venuda cap manera o per cap mitjà, totalment o parcialment, sense el previ consentiment per escrit d'Ares.
                                    </p>
                                    <p>
                                        Reservat el dret, a la nostra elecció exclusiva, de canviar, modificar, afegir o eliminar parts de les presents Condicions d'Ús en qualsevol moment i sense previ avís.
                                    </p>
                                    <p>
                                        Sempre que estiguis legitimat per utilitzar el Lloc, se't atorga una llicència limitada per utilitzar i accedir a el Lloc i a el Contingut de l'Lloc i per descarregar o imprimir una còpia de qualsevol part del Contingut de l'Lloc a la qual ja tinguis accés legítimament i només per al teu ús personal i no comercial, sempre que mantinguis intactes totes les advertències sobre drets d'autor i propietat intel·lectual; excepte pel que fa al teu propi contingut d 'usuari, no podràs carregar o republicar Contingut del Lloc en cap lloc d'Internet, Intranet o Extranet ni integrar la informació en cap altra base de dades o compilació; qualsevol altre ús de l'Contingut del Lloc queda estrictament prohibit.
                                    </p>
                                    <p>
                                        L'ús no autoritzat pot constituir així mateix una infracció de la legislació aplicable, incloent les lleis de marques i drets d'autor, i de les normatives i reglaments aplicables en matèria de comunicacions. Llevat que s'indiqui expressament el contrari en el present Contracte, res del que disposen les presents Condicions d'Ús haurà d'interpretar en el sentit d'atorgar una llicència sobre drets de propietat intel·lectual, ja sigui per impediment legal, implícitament o de qualsevol altra forma.
                                    </p>
                                    <p>
                                        Aquesta llicència podrà ser revocada en qualsevol moment i sense preavís, amb o sense causa.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container py-4">
                        <div className="text-center">
                            {Lang('COPYRIGHT')}
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}
