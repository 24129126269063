import React from "react";
import { useAxios } from "../../../hooks/useAxios";
import { useEffect } from "react";
import { isStatusSucess } from "../../../helpers/helpers";
import { Button, Form, Table } from "react-bootstrap";
import { Loader } from "../../../components/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ModalPagoCuota } from "./ModalPagoCuota";
import { useForm } from "../../../hooks/useForm";
import { ModalStatusServer2 } from "../../../components/ModalStatusServer/ModalStatusServer2";
import { ButtonSpinner } from "../../../components/ButtonSpinner/ButtonSpinner";
import { useModalData } from '../../../hooks/useModalData';

export const PagosCuotas = ({ id, fetchOrganizacionData }) => {

    const [fetchCuotasPendientes, fetchCuotasPendientesData, resetFetchCuotasPendientes] = useAxios('get', `/organizaciones`)

    const [formOrganizacion, handleInputChangeOrganizacion, handleFormChangeOrganizacion, handleResetOrganizacion]
        = useForm()

     const [dataModal_Pago, handleOpenModal_Pago, handleCloseModal_Pago] = useModalData()    

    useEffect(() => {

        if (isStatusSucess(fetchOrganizacionData.status)) {

            const org_id = fetchOrganizacionData.data?.id

            fetchCuotasPendientes({
                id: org_id + '/cuotas-pendientes'
            })
        }

    }, [fetchOrganizacionData]);

    useEffect(() => {

        if (isStatusSucess(fetchCuotasPendientesData.data?.status)) {

            handleFormChangeOrganizacion({
                exento_cuota: fetchOrganizacionData.data?.exento_cuota,
            })

        }
    }, [fetchCuotasPendientesData]);

    const [showModalPagoCuota, setShowModalPagoCuota] = useState({});

    const handleCloseModalPagoCuota = () => setShowModalPagoCuota({
        value: false,
        payload: {}
    })
    const handleShowModalPagoCuota = (payload = {}) => setShowModalPagoCuota({
        value: true,
        payload
    });

    const [fetchActivarExentoCuota, fetchActivarExentoCuotaData, resetFetchActivarExentoCuota] = useAxios('patch', `/organizaciones`)

    const handleChangeIntegracionApi = (e) => {

        handleFormChangeOrganizacion({
            ...formOrganizacion,
            exento_cuota: e.target.checked ? true : ''
        })

        fetchActivarExentoCuota({
            id: fetchOrganizacionData.data?.id + '/exento-cuota',
            body: {
                exento_cuota: e.target.checked ? true : false
            }
        })
    }

    useEffect(() => {

        if (isStatusSucess(fetchActivarExentoCuotaData.data?.status)) {

            fetchCuotasPendientes({
                id: fetchOrganizacionData.data?.id + '/cuotas-pendientes'
            })
        }

    }, [fetchActivarExentoCuotaData]);



    // ------------ DELETE pago cuota

    const [year, setyear] = useState('');


    const [fetchPagoDELETE, fetchPagoDELETEData] = useAxios('DELETE', '/cuotas/borrado-pago')

    const handleSubmitDeletePago = (item) => {
        fetchPagoDELETE({
            body: {
                cuotas: [
                    {
                        ...item,
                        org_id: Number(fetchOrganizacionData.data?.id || 0)
                    }
                ]
            }
        })
        setyear(item.year)
    }

    useEffect(() => {
        console.log(fetchPagoDELETEData)

        if (isStatusSucess(fetchPagoDELETEData?.data?.status)) {
            fetchCuotasPendientes({
                id: fetchOrganizacionData.data?.id + '/cuotas-pendientes'
            })
            handleCloseModalPagoCuota()
        }

    }, [fetchPagoDELETEData])



    return (
        <>
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp">

                {
                    fetchOrganizacionData?.loading ?
                        <div className="py-5 d-flex justify-content-center">
                            <Loader />
                        </div>
                        :
                        <>

                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <h5 className='mt-0'>Pagos cuotas</h5>
                                </div>

                                <div className='d-flex justify-content-end ms-auto'>
                                    <Button variant="outline-success" className="px-3 ms-3 d-flex align-items-center" onClick={
                                        () => handleOpenModal_Pago({
                                            action: 'CREATE',
                                        })
                                        }
                                    >
                                        <FontAwesomeIcon icon={faMoneyBill}/>
                                    </Button>
                                </div> 
                            </div>
                            {/*
                            <div className="d-flex justify-content-end mb-3">
                                <Form.Check
                                    type='checkbox'
                                    id='A1'
                                    label='Exento de cuotas anuales'
                                    className='mb-2'
                                    name='integracion_api'
                                    defaultChecked={fetchOrganizacionData.data?.exento_cuota}
                                    onChange={handleChangeIntegracionApi}
                                />
                             </div>
                             */}   

                            <Table hover bordered responsive className='tableMain'>
                                <thead>
                                    <tr>
                                        <th className='text-nowrap'>Año</th>
                                        <th className='text-nowrap'>Pagado</th>
                                        <th className='text-nowrap'>N° Orden</th>
                                        <th className='text-nowrap'>N° Factura</th>
                                        <th className='text-nowrap'>Fecha pago</th>
                                        <th className='text-nowrap'>Importe</th>
                                        <th className='text-nowrap'>Tipo</th>
                                        <th className='text-nowrap'>Observaciones</th>
                                        <th className='text-nowrap'>Método de pago</th>
                                        <th className="shrink-td"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        fetchCuotasPendientesData.loading ?
                                            <tr>
                                                <td className='text-center' colSpan={10}>
                                                    <Loader />
                                                </td>
                                            </tr>
                                            :
                                            fetchCuotasPendientesData.data?.data?.data?.length > 0 ? <>
                                                {
                                                    fetchCuotasPendientesData.data?.data?.data?.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {item.year}
                                                                </td>
                                                                <td>
                                                                    {item.pagado ? 'Si' : 'No'}
                                                                </td>
                                                                <td>
                                                                    {item.num_order}
                                                                </td>
                                                                <td>
                                                                    {item.num_factura}
                                                                </td>
                                                                <td>
                                                                    {item.pagado ? (new Date(item.pagado)?.toLocaleDateString()) : ''}
                                                                </td>
                                                                <td>
                                                                    {item.importe}
                                                                </td>
                                                                <td>
                                                                    {item.tipo}
                                                                </td>
                                                                <td>
                                                                    {item.observaciones}
                                                                </td>
                                                                <td>
                                                                    {item.metodo_pago}
                                                                </td>                                  
                                                                <td className="shrink-td">
                                                                    <Button variant="outline-danger" size="sm" className=''
                                                                        onClick={() => handleOpenModal_Pago({
                                                                            action: 'EDIT',
                                                                            year: item.year,
                                                                            org_id: item.org_id
                                                                        })}
                                                                    >
                                                                        <FontAwesomeIcon icon={faEdit}/>
                                                                    </Button>
                                                                    

                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </> : <tr>
                                                <td className='py-5 text-center' colSpan={10}>
                                                    <h5>No hay datos a mostrar</h5>
                                                </td>
                                            </tr>
                                    }


                                </tbody>
                            </Table>

                        </>
                }
            </div>

            <ModalPagoCuota
                data={dataModal_Pago}
                handleCloseModal={handleCloseModal_Pago}
                org_id={fetchOrganizacionData.data?.id}
                fetchSuccess={() => fetchCuotasPendientes({
                    id: fetchOrganizacionData.data?.id + '/cuotas-pendientes'
                })}
                /*showModalPagoCuota={showModalPagoCuota}
                handleCloseModalPagoCuota={handleCloseModalPagoCuota}
                */
            // formValuesSearch={formValuesSearch}
            />

            <ModalStatusServer2 fetchData={fetchActivarExentoCuotaData} />

            <ModalStatusServer2 fetchData={fetchPagoDELETEData} />

        </>
    );
};
