import { faSearch, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { AuthContext } from '../../../auth/AuthContext'
import { isModuleAdministrador } from '../../../helpers/helpers'
import { useLang } from '../../../language'
import { ModalTableOrganizacion } from '../../DAPcons/DAPconsFilter/ModalTableOrganizacion'
import { InputText } from '../../../components/Inputs'

export const UsuariosFilter = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchParametersData }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const Lang = useLang();

    
    // ----------- Filtro de organizacion

    const [organizacionSelect, setOrganizacionSelect] = useState({
        id: '',
        nombre: ''
    })

    // Modal

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        handleInputChangeSearch({
            target: {
                name: 'org_id',
                value: organizacionSelect.id
            }
        })
    }, [organizacionSelect])



    return (
        <div className='col-xl-3'>
            <div className='Column-small shadow-sm sticky-top'>

                <h5>{Lang('FILTRO')}</h5>
                <Form className='row' onSubmit={handleSearch}>

                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3">
                        <Form.Label className='fw-500'>Empresa:</Form.Label>
                        <InputGroup>
                            <Form.Control
                                value={organizacionSelect.nombre}
                                onChange={() => setOrganizacionSelect({ id: '', nombre: '' })}
                            />
                            <InputGroup.Text className='btn btn-sm btn-primary d-flex align-items-center' onClick={handleShow}>
                                <FontAwesomeIcon icon={faWarehouse} className='' />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3">
                        <InputText
                            label='Año cuota:'
                            className=''
                            name='year'
                            value={formValuesSearch.year}
                            onChange={handleInputChangeSearch}
                        />
                    </Form.Group>

                    <div className='mt-2'>
                        <button className="px-3 btn btn-primary float-end">
                            <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                        </button>
                    </div>
                </Form>
            </div>

            <ModalTableOrganizacion show={show} handleClose={handleClose} setOrganizacionSelect={setOrganizacionSelect} />

        </div>
    )
}
