//general utils
const schema = (item = {}) => {
    const data = {
        id: item.id || '',
        entidad: item.entidad || '',
        num_cuenta: item.num_cuenta || '',
    }
    return data;
}

const adaterGeneral = (data) => {
    const item = data?.data
    return {
        data: item,
        status: data?.status
    }
}

export default {schema, adaterGeneral}