import React, { useState, useContext, useEffect } from 'react'
import { Card, Form, Tabs, Tab, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserCog, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { InputText } from '../../../components/Inputs'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import ModalCrearEmpresa from '../ModalCrearEmpresa';
import ModalRecuperarContrasenia from '../ModalRecuperarContrasenia';
import ModalCrearConsultor from '../ModalCrearConsultor';

import { useForm } from '../../../hooks/useForm';
import { useValidator } from '../../../hooks/useValidator';
import { types } from '../../../types/types';

import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import { AuthContext } from '../../../auth/AuthContext';

import { useLang } from '../../../language';

import 'animate.css'
import './CardLogin.css'
import { ModalStatus } from '../../../components/ModalStatusServer/ModalStatus';
import ModalTerminosCondiciones from '../../../components/ModalStatic/ModalTerminosCondiciones'
import ModalProteccionDatos from '../../../components/ModalStatic/ModalProteccionDatos'
import { useLocation } from 'react-router-dom';
import { ModalPrompt } from '../../../components/ModalStatusServer/ModalPrompt';
import { guardarDatos } from '../../../helpers/helpers';

export const CardLogin = ({ fetchLoginEmpresaData, fetchLoginEmpresa, fetchLoginConsultorData, fetchLoginConsultor,
    fetchLoginAdminData, fetchLoginAdmin, areaAdmin }) => {

    const { user, dispatch } = useContext(AuthContext);

    const Lang = useLang();

    const userRecovery = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE + '_REMEMBER_PASSWORD')) || {}

    // --- Login ---

    const [formLoginEmpresa, handleInputChangeLoginEmpresa, handleFormChangeLoginEmpresa, handleResetLoginEmpresa] = useForm({
        usuario: userRecovery['organizacion']?.modulo_app ? userRecovery['organizacion'].usuario : '',
        password: userRecovery['organizacion']?.modulo_app ? userRecovery['organizacion'].password : '',
        modulo_app: 'organizacion',
    })

    const [valuesValidationLoginEmpresa, handleValidationLoginEmpresa, handleResetValidationLoginEmpresa] = useValidator({
        usuario: { isOk: true, msgError: [`El documento es obligatorio.`] },
        password: { isOk: true, msgError: [`La contraseña es obligatoria.`] },
    })

    const handleClickLoginEmpresa = (e) => {

        e.preventDefault()

        if (handleValidationLoginEmpresa(formLoginEmpresa)) fetchLoginEmpresa({
            body: formLoginEmpresa
        })

        if (checkedRememberPasswordOrg) {
            guardarDatos(formLoginEmpresa, 'organizacion')
        } else {
            guardarDatos({}, 'organizacion')
        }
    }

    // --- Login para Consultor ---

    const [formLoginConsultor, handleInputChangeLoginConsultor, handleFormChangeLoginConsultor, handleResetLoginConsultor] = useForm({
        usuario: userRecovery['consultor']?.modulo_app ? userRecovery['consultor'].usuario : '',
        password: userRecovery['consultor']?.modulo_app ? userRecovery['consultor'].password : '',
        modulo_app: 'consultor',
    })

    const [valuesValidationLoginConsultor, handleValidationLoginConsultor, handleResetValidationLoginConsultor] = useValidator({
        usuario: { isOk: true, msgError: [`El documento es obligatorio.`] },
        password: { isOk: true, msgError: [`La contraseña es obligatoria.`] },
    })

    const handleClickLoginConsultor = (e) => {

        e.preventDefault()

        if (handleValidationLoginConsultor(formLoginConsultor)) fetchLoginConsultor({
            body: formLoginConsultor
        })
        if (checkedRememberPasswordCons) {
            guardarDatos(formLoginConsultor, 'consultor')
        } else {
            guardarDatos({}, 'consultor')
        }
    }

    // --- Login para admin ---

    const [formLoginAdmin, handleInputChangeLoginAdmin, handleFormChangeLoginAdmin, handleResetLoginAdmin] = useForm({
        usuario: userRecovery['administrador']?.modulo_app ? userRecovery['administrador'].usuario : '',
        password: userRecovery['administrador']?.modulo_app ? userRecovery['administrador'].password : '',
        modulo_app: 'administrador',
    })

    const [valuesValidationLoginAdmin, handleValidationLoginAdmin, handleResetValidationLoginAdmin] = useValidator({
        usuario: { isOk: true, msgError: [`El documento es obligatorio.`] },
        password: { isOk: true, msgError: [`La contraseña es obligatoria.`] },
    })

    const handleClickLoginAdmin = (e) => {

        e.preventDefault()

        if (handleValidationLoginAdmin(formLoginAdmin)) fetchLoginAdmin({
            body: formLoginAdmin
        })

        if (checkedRememberPasswordAdmin) {
            guardarDatos(formLoginAdmin, 'administrador')

        } else {
            guardarDatos({}, 'administrador')
        }

    }

    // Cambiar idioma

    const handdleChangeLanguage = (lang) => {

        dispatch({
            type: types.logout,
            ...user,
            lang
        })
    }

    // para los tabs

    const [key, setKey] = useState('empresa');

    useEffect(() => {

        if (areaAdmin) {

            setKey('admin')

        } else {

            setKey('empresa')
        }

    }, [areaAdmin])

    // Registro Empresa

    const [showModalRegistroEmpresa, setShowModalRegistroEmpresa] = useState(false);

    const handleCloseModalRegistroEmpresa = () => setShowModalRegistroEmpresa(false)
    const handleShowModalRegistroEmpresa = () => setShowModalRegistroEmpresa(true);

    // Registro Consultor

    const [showModalRegistroConsultor, setShowModalRegistroConsultor] = useState(false);

    const handleCloseModalRegistroConsultor = () => setShowModalRegistroConsultor(false)
    const handleShowModalRegistroConsultor = () => setShowModalRegistroConsultor(true);

    // Recuperar Contraseña

    const [showModalRecuperarContrasenia, setShowModalRecuperarContrasenia] = useState({
        value: false,
        payload: ''
    });

    const handleCloseModalRecuperarContrasenia = () => setShowModalRecuperarContrasenia({
        value: false,
        payload: ''
    })
    const handleShowModalRecuperarContrasenia = (payload) => setShowModalRecuperarContrasenia({
        value: true,
        payload
    });


    // Terminos y condiciones

    const [showModalTerminosCondiciones, setShowModalTerminosCondiciones] = useState(false)

    const handleCloseModalTerminosCondiciones = () => setShowModalTerminosCondiciones(false)
    const handleShowModalTerminosCondiciones = () => setShowModalTerminosCondiciones(true)

    const [org_confrm_acepta_terminos_condiciones, setOrg_confrm_acepta_terminos_condiciones] = useState(false)

    const handleChangeManifesto = () => {
        console.log('cambiando a true')
        setOrg_confrm_acepta_terminos_condiciones(true)
        handleCloseModalTerminosCondiciones()
    }

    // Proteccion Datos

    const [showModalProteccionDatos, setShowModalProteccionDatos] = useState(false)

    const handleCloseModalProteccionDatos = () => setShowModalProteccionDatos(false)
    const handleShowModalProteccionDatos = () => setShowModalProteccionDatos(true)

    const [org_confrm_acepta_prot_datos_pers, setOrg_confrm_acepta_prot_datos_pers] = useState(false)
    const [consultor_manif_prot_datos_pers, setConsultor_manif_prot_datos_pers] = useState(false)

    const handleChangeProteccionDatos = () => {
        console.log('cambiando a true')
        if (showModalRegistroEmpresa) {
            setOrg_confrm_acepta_prot_datos_pers(true)
        }
        if (showModalRegistroConsultor) {
            setConsultor_manif_prot_datos_pers(true)
        }

        handleCloseModalProteccionDatos()
    }


    const [showConfirmacion, setShowConfirmacion] = useState({
        value: false,
        index: -1
    })

    const [
        checkedRememberPasswordOrg,
        setcheckedRememberPasswordOrg
    ] = useState(userRecovery['organizacion']?.modulo_app ? true : false)

    const [
        checkedRememberPasswordCons,
        setcheckedRememberPasswordCons
    ] = useState(userRecovery['consultor']?.modulo_app ? true : false)

    const [
        checkedRememberPasswordAdmin,
        setcheckedRememberPasswordAdmin
    ] = useState(userRecovery['administrador']?.modulo_app ? true : false)

    const handleClickGuardarDatos = (modulo_app) => {

        console.log(modulo_app)
        switch (modulo_app) {
            case 'organizacion':
                setcheckedRememberPasswordOrg(true)
                break;

            case 'consultor':
                setcheckedRememberPasswordCons(true)
                break;

            case 'administrador':
                setcheckedRememberPasswordAdmin(true)
                break;

            default:
                break;
        }

        setShowConfirmacion({ value: false, index: -1 })

    }

    return (
        <>
            {
                areaAdmin &&
                <div className="w-100 d-flex justify-content-center align-items-md-center flex-md-column flex-grow-1 mb-lg-5 animate__animated animate__fadeInUp">

                    <div className='col-11 col-sm-9 col-lg-12 col-xl-12 col-xxl-12 mb-5 mb-lg-0 pt-5 pt-xl-2 mt-5 mt-xl-0'>

                        <h4 className='text-center my-4'>Programa DAPconstrucción®</h4>

                        <Tabs
                            activeKey={key}
                            transition={false}
                            onSelect={(k) => setKey(k)}
                            id="tabw"
                        >
                            <Tab eventKey="admin" title={<span><FontAwesomeIcon icon={faUserCog} className='me-2' />Área administració</span>}>

                                <div className="d-flex justify-content-center">
                                    <div className='col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4'>
                                        <Card className="shadow-sm">
                                            {/* <Card.Img variant="top" src="images/logo.png" id="logo" /> */}
                                            <Card.Body className="px-4 py-md-4">
                                                <h5 className='mt-2 mb-3'>Inicia sesión</h5>
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <InputText
                                                            label='NIF'
                                                            className=''
                                                            name='usuario'
                                                            value={formLoginAdmin.usuario}
                                                            onChange={handleInputChangeLoginAdmin}
                                                            validation={valuesValidationLoginAdmin}
                                                            fetchData={fetchLoginAdminData}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <InputText
                                                            label={Lang('CONTRASENIA')}
                                                            className=''
                                                            name='password'
                                                            type="password"
                                                            value={formLoginAdmin.password}
                                                            onChange={handleInputChangeLoginAdmin}
                                                            // placeholder="password"
                                                            validation={valuesValidationLoginAdmin}
                                                            fetchData={fetchLoginAdminData}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Check
                                                            type='checkbox'
                                                            name='rememberpasswordAdmin'
                                                            checked={checkedRememberPasswordAdmin}
                                                            id='rememberpasswordAdmin'
                                                            onChange={(e) => {
                                                                if (e.target.checked === true) {
                                                                    e.preventDefault()
                                                                    setShowConfirmacion({ value: true, index: 'administrador' })
                                                                }
                                                                else {
                                                                    setcheckedRememberPasswordAdmin(false)
                                                                }
                                                            }}
                                                            label={
                                                                <div className="cursor-pointer">
                                                                    <span>Guardar Contraseña</span>
                                                                </div>
                                                            }
                                                        />
                                                    </Form.Group>


                                                    <div className="text-center">
                                                        <MsgInputServer fetchData={fetchLoginAdminData} />
                                                    </div>

                                                    <div className="text-end mt-4 d-flex flex-wrap justify-content-end align-items-center">
                                                        <ButtonSpinner
                                                            variant="primary" type="submit" className="px-4 w-100"
                                                            handleSubmit={handleClickLoginAdmin} fetchDataLoading={[fetchLoginAdminData.loading]}
                                                            value='Iniciar sesión'
                                                        />
                                                    </div>
                                                </Form>
                                            </Card.Body>
                                            <Card.Footer className="text-muted">
                                                <button type="button" id="passwordHelp" className="form-text my-0" onClick={() => handleShowModalRecuperarContrasenia('administrador')}>
                                                    {Lang('OLVIDASTE_CONTRASENA')}
                                                </button>
                                            </Card.Footer>

                                        </Card>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            }
            {
                !areaAdmin &&
                <div className="w-100 d-flex justify-content-center align-items-md-center flex-md-column flex-grow-1 mb-lg-5 animate__animated animate__fadeInUp">

                    <div className='col-11 col-sm-9 col-lg-12 col-xl-12 col-xxl-12 mb-5 mb-lg-0 pt-5 pt-xl-2 mt-5 mt-xl-0'>

                        <h4 className='text-center my-4'>Programa DAPconstrucción®</h4>

                        <Tabs
                            activeKey={key}
                            // transition={false}
                            onSelect={(k) => setKey(k)}
                            id="noanim-tab-example"
                        >
                            <Tab eventKey="empresa" title={<span><FontAwesomeIcon icon={faWarehouse} className='me-2' />Empresa</span>}>

                                <div className="d-flex justify-content-center container-login flex-column flex-md-row">
                                    <div className='col-12 col-md-6 col-lg-6 col-xl-5 col-xxl-4'>
                                        <Card className="shadow-sm">
                                            {/* <Card.Img variant="top" src="images/logo.png" id="logo" /> */}
                                            <Card.Body className="px-4 py-md-4">
                                                <h5 className='mt-2 mb-3'>Inicia sesión</h5>
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <InputText
                                                            label='CIF'
                                                            className=''
                                                            name='usuario'
                                                            value={formLoginEmpresa.usuario}
                                                            onChange={handleInputChangeLoginEmpresa}
                                                            validation={valuesValidationLoginEmpresa}
                                                            fetchData={fetchLoginEmpresaData}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <InputText
                                                            label={Lang('CONTRASENIA')}
                                                            className=''
                                                            name='password'
                                                            type="password"
                                                            value={formLoginEmpresa.password}
                                                            onChange={handleInputChangeLoginEmpresa}
                                                            validation={valuesValidationLoginEmpresa}
                                                            fetchData={fetchLoginEmpresaData}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Check
                                                            type='checkbox'
                                                            name='rememberpasswordOrg'
                                                            checked={checkedRememberPasswordOrg}
                                                            id='rememberpasswordOrg'
                                                            onChange={(e) => {
                                                                if (e.target.checked === true) {
                                                                    e.preventDefault()
                                                                    setShowConfirmacion({ value: true, index: 'organizacion' })
                                                                }
                                                                else {
                                                                    setcheckedRememberPasswordOrg(false)
                                                                }
                                                            }}
                                                            label={
                                                                <div className="cursor-pointer">
                                                                    <span>Guardar Contraseña</span>
                                                                </div>
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <div className="text-center">
                                                        <MsgInputServer fetchData={fetchLoginEmpresaData} />
                                                    </div>

                                                    <div className="text-end mt-4 d-flex flex-wrap justify-content-end align-items-center">
                                                        <ButtonSpinner
                                                            variant="primary" type="submit" className="px-4 w-100"
                                                            handleSubmit={handleClickLoginEmpresa} fetchDataLoading={[fetchLoginEmpresaData.loading]}
                                                            value='Iniciar sesión'
                                                        />
                                                    </div>
                                                </Form>
                                            </Card.Body>
                                            <Card.Footer className="text-muted">
                                                <button type="button" id="passwordHelp" className="form-text my-0" onClick={() => handleShowModalRecuperarContrasenia('organizacion')}>
                                                    {Lang('OLVIDASTE_CONTRASENA')}
                                                </button>
                                            </Card.Footer>

                                        </Card>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-5 col-xl-5 col-xxl-4'>
                                        <Card className="shadow-sm">
                                            {/* <Card.Img variant="top" src="images/logo.png" id="logo" /> */}
                                            <Card.Body className="px-4 py-md-4">
                                                <h5 className='mt-2 mb-3'>Registro</h5>
                                                <Form>
                                                    <button type="button" className="form-text my-0 btn btn-outline-primary w-100" onClick={handleShowModalRegistroEmpresa}>
                                                        Registrarme
                                                    </button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Consultor" title={<span><FontAwesomeIcon icon={faUser} className='me-2' />Consultor</span>}>

                                <div className="d-flex justify-content-center container-login flex-column flex-md-row">
                                    <div className='col-12 col-md-6 col-lg-6 col-xl-5 col-xxl-4'>
                                        <Card className="shadow-sm">
                                            {/* <Card.Img variant="top" src="images/logo.png" id="logo" /> */}
                                            <Card.Body className="px-4 py-md-4">
                                                <h5 className='mt-2 mb-3'>Inicia sesión</h5>
                                                <Form>
                                                    <Form.Group className="mb-3">
                                                        <InputText
                                                            label='NIF'
                                                            className=''
                                                            name='usuario'
                                                            value={formLoginConsultor.usuario}
                                                            onChange={handleInputChangeLoginConsultor}
                                                            validation={valuesValidationLoginConsultor}
                                                            fetchData={fetchLoginConsultorData}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <InputText
                                                            label={Lang('CONTRASENIA')}
                                                            className=''
                                                            name='password'
                                                            type="password"
                                                            value={formLoginConsultor.password}
                                                            onChange={handleInputChangeLoginConsultor}
                                                            validation={valuesValidationLoginConsultor}
                                                            fetchData={fetchLoginConsultorData}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Check
                                                            type='checkbox'
                                                            name='rememberpasswordCons'
                                                            checked={checkedRememberPasswordCons}
                                                            id='rememberpasswordCons'
                                                            onChange={(e) => {
                                                                if (e.target.checked === true) {
                                                                    e.preventDefault()
                                                                    setShowConfirmacion({ value: true, index: 'consultor' })
                                                                }
                                                                else {
                                                                    setcheckedRememberPasswordCons(false)
                                                                }
                                                            }}
                                                            label={
                                                                <div className="cursor-pointer">
                                                                    <span>Guardar Contraseña</span>
                                                                </div>
                                                            }
                                                        />
                                                    </Form.Group>

                                                    <div className="text-center">
                                                        <MsgInputServer fetchData={fetchLoginConsultorData} />
                                                    </div>

                                                    <div className="text-end mt-4 d-flex flex-wrap justify-content-end align-items-center">
                                                        <ButtonSpinner
                                                            variant="primary" type="submit" className="px-4 w-100"
                                                            handleSubmit={handleClickLoginConsultor} fetchDataLoading={[fetchLoginConsultorData.loading]}
                                                            value='Iniciar sesión'
                                                        />
                                                    </div>
                                                </Form>
                                            </Card.Body>
                                            <Card.Footer className="text-muted">
                                                <button type="button" id="passwordHelp" className="form-text my-0" onClick={() => handleShowModalRecuperarContrasenia('consultor')}>
                                                    {Lang('OLVIDASTE_CONTRASENA')}
                                                </button>
                                            </Card.Footer>

                                        </Card>
                                    </div>
                                    <div className='col-12 col-md-6 col-lg-5 col-xl-5 col-xxl-4'>
                                        <Card className="shadow-sm">
                                            {/* <Card.Img variant="top" src="images/logo.png" id="logo" /> */}
                                            <Card.Body className="px-4 py-md-4">
                                                <h5 className='mt-2 mb-3'>Registro</h5>
                                                <Form>
                                                    <button type="button" className="form-text my-0 btn btn-outline-primary w-100" onClick={handleShowModalRegistroConsultor}>
                                                        Registrarme
                                                    </button>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            }

            <ModalCrearEmpresa
                showModalRegistroEmpresa={showModalRegistroEmpresa}
                handleCloseModalRegistroEmpresa={handleCloseModalRegistroEmpresa}
                handleShowModalTerminosCondiciones={handleShowModalTerminosCondiciones}
                handleShowModalProteccionDatos={handleShowModalProteccionDatos}
                org_confrm_acepta_terminos_condiciones={org_confrm_acepta_terminos_condiciones}
                setOrg_confrm_acepta_terminos_condiciones={setOrg_confrm_acepta_terminos_condiciones}
                org_confrm_acepta_prot_datos_pers={org_confrm_acepta_prot_datos_pers}
                setOrg_confrm_acepta_prot_datos_pers={setOrg_confrm_acepta_prot_datos_pers}
            // setShowStatusVerify2={setShowStatusVerify2}
            />

            <ModalCrearConsultor
                showModalRegistroConsultor={showModalRegistroConsultor}
                handleCloseModalRegistroConsultor={handleCloseModalRegistroConsultor}
                // setShowStatusVerify2={setShowStatusVerify2}
                consultor_manif_prot_datos_pers={consultor_manif_prot_datos_pers}
                setConsultor_manif_prot_datos_pers={setConsultor_manif_prot_datos_pers}
                handleShowModalProteccionDatos={handleShowModalProteccionDatos}
            />

            <ModalRecuperarContrasenia
                showModalRecuperarContrasenia={showModalRecuperarContrasenia}
                handleCloseModalRecuperarContrasenia={handleCloseModalRecuperarContrasenia}
            />

            <ModalTerminosCondiciones
                showModalTerminosCondiciones={showModalTerminosCondiciones}
                setShowModalTerminosCondiciones={setShowModalTerminosCondiciones}
                handleCloseModalTerminosCondiciones={handleCloseModalTerminosCondiciones}
                handleSubmit={handleChangeManifesto}
            />

            <ModalProteccionDatos
                showModalProteccionDatos={showModalProteccionDatos}
                setShowModalProteccionDatos={setShowModalProteccionDatos}
                handleCloseModalProteccionDatos={handleCloseModalProteccionDatos}
                handleSubmit={handleChangeProteccionDatos}
            />

            <ModalPrompt setShowStatus={setShowConfirmacion} showStatus={showConfirmacion}>
                <Modal.Body>
                    <div className="py-0 text-center">
                        <h5 className='mb-4 fw-normal'>
                            Guardar Datos
                        </h5>
                        <p className='mb-0 px-3'>
                            ¿Está seguro que desea guardar sus datos en este ordenador?
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="default" onClick={() => setShowConfirmacion({ value: false, index: -1 })}>
                        Salir
                    </Button>
                    <Button variant="primary" onClick={() => handleClickGuardarDatos(showConfirmacion.index)}>
                        Si, guardar datos
                    </Button>
                </Modal.Footer>
            </ModalPrompt>

        </>
    )
}