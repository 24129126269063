import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { Form } from 'react-bootstrap'
import { AuthContext } from '../../../auth/AuthContext'
import { isModuleAdministrador } from '../../../helpers/helpers'
import { useLang } from '../../../language'

export const UsuariosFilter = ({ handleSearch, formValuesSearch, handleInputChangeSearch, fetchParametersData }) => {

    const { user: { perfil, modulo_app } } = useContext(AuthContext);

    const Lang = useLang();

    return (
        <div className='col-xl-3'>
            <div className='Column-small shadow-sm sticky-top'>

                <h5>{Lang('FILTRO')}</h5>
                <Form className='row' onSubmit={handleSearch}>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>NIF</Form.Label>
                        <Form.Control type="text" autoComplete="off" name='nif' value={formValuesSearch.nif} onChange={handleInputChangeSearch} />
                    </Form.Group>


                    {/* <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>Col·legi</Form.Label>
                        <Form.Select aria-label="Default select example"
                            value={formValuesSearch.colegio_id}
                            name='colegio_id'
                            onChange={handleInputChangeSearch}>
                            <option></option>
                            {fetchParametersData?.data?.data?.colegios?.map((item) => {
                                return (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group> */}
                    <Form.Group className="col-12 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label>{Lang('ESTADO')}</Form.Label>
                        <Form.Select aria-label="Default select example" value={formValuesSearch.estado_id} name='estado_id' onChange={handleInputChangeSearch}>
                            <option></option>
                            {fetchParametersData?.data?.data?.estados_colegiacion?.map((item) => {
                                return (
                                    <option key={item.id} value={item.id}>{item.nombre}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>

                    <div className="col-12 col-sm-12"></div>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>{Lang('DESDE')}</Form.Label>
                        <Form.Control type="date" autoComplete="off" />
                    </Form.Group>

                    <Form.Group className="col-6 col-sm-4 col-xl-12 mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>{Lang('HASTA')}</Form.Label>
                        <Form.Control type="date" autoComplete="off" />
                    </Form.Group>



                    <div className='mt-2'>
                        <button className="px-3 btn btn-primary float-end">
                            <FontAwesomeIcon icon={faSearch} /> {Lang('BUSCAR')}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    )
}
