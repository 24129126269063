import { faArrowDown, faArrowRight, faFile, faFilePdf, faSave, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Modal, Table, Form } from 'react-bootstrap'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../hooks/useAxios'
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer';
import { useForm } from '../../hooks/useForm';
import { useValidator } from '../../hooks/useValidator';
import { InputText } from '../../components/Inputs';

export const ModalConfirmarPagoDapcons = ({ data, handleCloseModal, fetchConsolidado}) => {
    const [fetchConfirmar, fetchConfirmarData] = useAxios('PATCH', '/panel/confirmar_pago_dapcons')

     const [formConfirmarPago, 
        handleInputChangeConfirmarPago, 
        handleFormChangeConfirmarPago, 
        handleResetConfirmarPago] = useForm({pagado: ''})

     const [valuesValidConfirmacion, handleVerifiedValuesConfirmacion, handleResetValuesConfirmacion] = useValidator({
        pagado: { isOk: true, msgError: [`Ingrese una fecha`] },
     })

    const handleSubmitConfirmarPago = (e) => {
        e.preventDefault();
        if(handleVerifiedValuesConfirmacion(formConfirmarPago)){
            fetchConfirmar({
                id: data?.id,
                body: {
                    pagado: formConfirmarPago.pagado
                }
            })
        }
    }

    useEffect(() => {
        if(!data.value){
            handleResetValuesConfirmacion();
            handleResetConfirmarPago()
        }
    },[data.value])

    useEffect(() => {
      if(fetchConfirmarData?.data?.status == 200){
            handleCloseModal();
            fetchConsolidado();
      }  
    },[fetchConfirmarData.data])

    return (
        <Modal show={data.value} onHide={handleCloseModal} size='' backdrop="static" keyboard={false}>
             <Modal.Header closeButton className='border-0'>
                <Modal.Title className='h5'>Confirmar pago (Dapcons N° {data?.id})</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4 py-4'>
                 <Form.Group className="mb-4 col-lg-12">
                     <InputText
                            label='Fecha de pago:'
                            type="date"
                            className=''
                            name='pagado'
                            placeholder="Ingrese una fecha"
                            value={formConfirmarPago.pagado}
                            onChange={handleInputChangeConfirmarPago}
                            validation={valuesValidConfirmacion}
                            fetchData={fetchConfirmarData}
                        />
                </Form.Group>
                <MsgInputServer fetchData={fetchConfirmarData} className='mt-2' />
            </Modal.Body>
            <Modal.Footer className="border-0">
                <Button variant="default" onClick={() => {
                    handleCloseModal()
                }}>
                    Cancelar
                </Button>
                <ButtonSpinner
                        variant="danger" type="submit" className="ms-2 mt-2"
                        handleSubmit={handleSubmitConfirmarPago} fetchDataLoading={[fetchConfirmarData.loading]}
                        value='Confirmar' icon={<FontAwesomeIcon icon={faArrowRight} className='me-1' />}
                    />
            </Modal.Footer>
        </Modal>
    )
}
