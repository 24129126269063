// validationHelpers.js

// Validar NIF
export const isValidNIF = (nif) => {
  const nifRegex = /^[0-9]{8}[A-Z]$/;
  if (!nifRegex.test(nif)) return false;
  const number = nif.slice(0, -1);
  const letter = nif.slice(-1);
  const validLetters = "TRWAGMYFPDXBNJZSQVHLCKE";
  const calculatedLetter = validLetters[number % 23];
  return letter === calculatedLetter;
};

// Validar CIF
export const isValidCIF = (cif) => {
  const cifRegex = /^[A-Z][0-9]{8}[A-J0-9]$/;
  if (!cifRegex.test(cif)) return false;
  const letter = cif[0];
  const numbers = cif.slice(1, -1);
  const finalLetter = cif.slice(-1);
  const validLetters = "ABCDEFGHJNPQRSUVW";
  if (!validLetters.includes(letter)) return false;
  const evenSum = numbers
    .split('')
    .filter((_, i) => i % 2 === 0)
    .map(num => parseInt(num, 10) * 2)
    .reduce((acc, curr) => acc + (curr > 9 ? curr - 9 : curr), 0);
  const oddSum = numbers
    .split('')
    .filter((_, i) => i % 2 !== 0)
    .map(num => parseInt(num, 10))
    .reduce((acc, curr) => acc + curr, 0);
  const totalSum = evenSum + oddSum;
  const controlDigit = (10 - (totalSum % 10)) % 10;
  const validControlLetters = "JABCDEFGHI";
  const validLetter = validControlLetters[controlDigit] || controlDigit;
  return finalLetter === validLetter;
};

// Validar NIE
export const isValidNIE = (nie) => {
  const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;
  if (!nieRegex.test(nie)) return false;
  const letterMap = { X: '0', Y: '1', Z: '2' };
  const convertedNIE = letterMap[nie[0]] + nie.slice(1);
  return isValidNIF(convertedNIE);
};

// Validar ID genérico (NIF, CIF, NIE)
export const validateID = (id) => {
  // Limpiar el ID de espacios en blanco
  const cleanedId = id.trim().toUpperCase();

  // Detectar y validar tipo
  if (/^[0-9]{8}[A-Z]$/.test(cleanedId)) {
    return isValidNIF(cleanedId);
  } else if (/^[A-Z][0-9]{8}[A-J0-9]$/.test(cleanedId)) {
    return isValidCIF(cleanedId);
  } else if (/^[XYZ][0-9]{7}[A-Z]$/.test(cleanedId)) {
    return isValidNIE(cleanedId);
  }
  return false;
};
