import { useState, useEffect  } from 'react';

export const useValidator = (initialState) => {

    const [valuesValid, setvaluesValid] = useState(initialState)

    const handleVerifiedValues = (objValues) => {

        const valuesValidTemp = { ...valuesValid }

        for (const key in valuesValidTemp) {

            console.log(valuesValidTemp, key, objValues)

            if (objValues[key] !== undefined && objValues[key] !== null && objValues[key] !== '') {
                if (Array.isArray(objValues[key]) && objValues[key].length === 0) {
                    valuesValidTemp[key]['isOk'] = false;
                } else {
                    valuesValidTemp[key]['isOk'] = true;
                }
            } else {

                valuesValidTemp[key]['isOk'] = false
            }
        }

        console.log(valuesValidTemp)
        setvaluesValid({
            ...valuesValidTemp
        })

        const objParse = JSON.stringify(valuesValidTemp)

        if (objParse.indexOf('"isOk":false') === -1)
            return true

        return false
    }

    const resetValuesValid = () => {

        const valuesValidTemp = { ...valuesValid }

        for (const key in valuesValidTemp) {

            valuesValidTemp[key]['isOk'] = true
        }

        setvaluesValid({
            ...valuesValidTemp
        })
    }

    const setValuesToNewState = (newValues) => {
        setvaluesValid(newValues);
    };

    return [valuesValid, handleVerifiedValues, resetValuesValid, setValuesToNewState]

}