import React from 'react';

import '../AvisLegal/AvisLegal.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import { NavTopLegal } from '../../components/NavTopLegal/NavTopLegal';
import { Table } from 'react-bootstrap';
import { useLang } from '../../language';

export const PoliticaCookies = () => {

    const Lang = useLang();

    return (
        <div className="App">
            <section id="NavTop-legal">
                <NavTopLegal />
                <section id="Main-legal">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="Main-legal-content shadow-sm animate__animated animate__fadeInUp">
                                    <h4 className="text-center">Política de Cookies</h4>
                                    <p>
                                        Aquesta política de cookies té per finalitat informar de manera clara i precisa sobre
                                        les cookies que s'utilitzen al nostre web.
                                    </p>
                                    <p>
                                        Utilitzem cookies pròpies i de tercers per millorar els nostres serveis, personalitzar
                                        el nostre lloc web, facilitar la navegació dels nostres usuaris, proporcionar-li una
                                        millor experiència en l'ús de la pàgina web, identificar problemes per millorar el
                                        mateix, fer amidaments i estadístiques d'ús i mostrar-li publicitat relacionada amb les
                                        seves preferències mitjançant l'anàlisi de l'ús de la pàgina web.
                                    </p>
                                    <p>
                                        L'informem que podem utilitzar cookies en el seu equip a condició que l'usuari hagi
                                        donat el seu consentiment, excepte en els supòsits en què les galetes siguin necessàries
                                        per a la navegació pel nostre lloc web. En cas que presti el seu consentiment, podrem
                                        utilitzar cookies que ens permetran tenir més informació sobre les seves preferències i
                                        personalitzar el nostre lloc web de conformitat amb els seus interessos individuals.
                                    </p>
                                    <h5 className="mt-4 mb-4 mb-md-4 fw-bold h5">TIPUS DE COOKIES</h5>
                                    <h5>Tipus de galetes en funció de l'entitat que les gestioni:</h5>
                                    <p>
                                        Galetes pròpies: són aquelles que s'envien a l'equip terminal de l'usuari des d'un equip
                                        o domini gestionat pel titular de la pàgina web i des de la qual es presta el servei
                                        sol·licitat per l'usuari.
                                    </p>
                                    <p>
                                        Galetes de tercers: són aquelles que s'envien a l'equip terminal de l'usuari des d'un
                                        equip o domini que no és gestionat pel titular de la pàgina web des de la qual es presta
                                        el servei sol·licitat per l'usuari, sinó per una altra entitat que tracta les dades
                                        obtinguts través de les cookies. Així mateix, en el cas que les galetes siguin
                                        instal·lades des d'un equip o domini gestionat pel mateix titular de el lloc web però la
                                        informació que es reculli mitjançant aquestes sigui gestionada per un tercer, també
                                        seran considerades com a galetes de tercers.
                                    </p>
                                    <h5>Tipus de galetes segons la finalitat:</h5>
                                    <p>
                                        Galetes tècniques: són aquelles que permeten a l'usuari la navegació a través d'una
                                        pàgina web, plataforma o aplicació i la utilització de les diferents opcions o serveis
                                        que en ella hi hagi, incloent aquelles que l'editor utilitza per permetre la gestió i
                                        operativa de la pàgina web i habilitar les seves funcions i serveis, com, per exemple,
                                        controlar el trànsit i la comunicació de dades, identificar la sessió, accedir a parts
                                        d'accés restringit, recordar els elements que integren una comanda, realitzar el procés
                                        de compra d'una comanda, gestionar el pagament, controlar el frau vinculat a la
                                        seguretat de l'servei, realitzar la sol·licitud d'inscripció o participació en un
                                        esdeveniment, comptar visites a efectes de la facturació de llicències de programari amb
                                        el que funciona el servei (lloc web, plataforma o aplicació) , utilitzar elements de
                                        seguretat durant la navegació, emmagatzemar continguts per a la difusió de vídeos o so,
                                        habilitació de contingut dinàmics (per exemple, ani ció de càrrega d'un text o imatge) o
                                        compartir continguts a través de xarxes socials.
                                    </p>
                                    <p>
                                        També dins d'aquesta categoria, per la seva naturalesa tècnica, aquelles galetes que
                                        permeten la gestió, de la forma més eficaç possible, dels espais publicitaris que, com
                                        un element més de disseny o "maquetació" de l'servei ofert a l'usuari, l'editor hagi
                                        inclòs en una pàgina web, aplicació o plataforma en base a criteris com el contingut
                                        editat, sense que es recopili informació dels usuaris amb finalitats diferents, com pot
                                        ser personalitzar aquest contingut publicitari o altres continguts.
                                    </p>
                                    <p>
                                        Galetes de preferències o personalització: són aquelles que permeten recordar informació
                                        perquè l'usuari accedeixi a el servei amb determinades característiques que poden
                                        diferenciar la seva experiència de la d'altres usuaris, com, per exemple, l'idioma, el
                                        nombre de resultats a mostrar quan l'usuari realitza una recerca, l'aspecte o contingut
                                        de l'servei en funció de l'tipus de navegador a través del qual l'usuari accedeix a
                                        l'servei o de la regió des de la qual s'accedeix a el servei, etc.
                                    </p>
                                    <p>
                                        Galetes d'anàlisi o mesurament: són aquelles que permeten el responsable de les mateixes
                                        el seguiment i anàlisi de l'comportament dels usuaris dels llocs web als que estan
                                        vinculades, inclosa la quantificació dels impactes dels anuncis. La informació recollida
                                        mitjançant aquest tipus de galetes s'utilitza en el mesurament de l'activitat dels llocs
                                        web, aplicació o plataforma, per tal d'introduir millores en funció de l'anàlisi de les
                                        dades d'ús que fan els usuaris de el servei.
                                    </p>
                                    <p>
                                        Galetes de publicitat comportamental: són aquelles que permeten la gestió, de la forma
                                        més eficaç possible, dels espais publicitaris que hi ha a la pàgina web. Aquestes
                                        galetes emmagatzemen informació de l'comportament dels usuaris obtinguda a través de
                                        l'observació continuada dels seus hàbits de navegació, el que permet desenvolupar un
                                        perfil específic per mostrar publicitat en funció de la mateixa.
                                    </p>
                                    <p>
                                        Galetes de geolocalització: s'utilitzen per saber en quin lloc es troba un usuari quan
                                        sol·licita un servei. Aquesta galeta és anònima i s'usa per exemple per oferir-li la
                                        informació adequada depenent de país en el qual es trobi.
                                    </p>

                                    <h5>Tipus de galetes segons termini de temps que romanen activades:</h5>

                                    <p>
                                        Galetes de sessió: són aquelles dissenyades per a demanar i emmagatzemar dades mentre
                                        l'usuari accedeix a una pàgina web. Se solen emprar per emmagatzemar informació que
                                        només interessa conservar per a la prestació de el servei sol·licitat per l'usuari en
                                        una sola ocasió (per exemple, una llista de productes adquirits) i desapareixen a
                                        l'acabar la sessió.
                                    </p>

                                    <p>
                                        Galetes persistents: són aquelles en què les dades segueixen emmagatzemats en el
                                        terminal i poden ser accedits i tractats durant un període definit pel responsable de la
                                        galeta, i que pot anar d'uns minuts a diversos anys.
                                    </p>

                                    <h5>COOKIES UTILITZADES A LA NOSTRA WEB</h5>

                                    <Table responsive>
                                        <thead>
                                            <tr><th>Denominació</th>
                                                <th>Titular</th>
                                                <th>Durada</th>
                                                <th>Finalitat</th>
                                            </tr></thead>
                                        <tbody>
                                            <tr>
                                                <td>CookieBearerToken</td>
                                                <td>Cookie Tècnica</td>
                                                <td>9 hores</td>
                                                <td>Registre de control d'accés i informació de sessió com a idioma d'accés</td>
                                            </tr>
                                            <tr>
                                                <td>PHPSESSID</td>
                                                <td>Cookie Tècnica</td>
                                                <td>Sessió</td>
                                                <td>Conté l'identificador de la sessió. S'elimina a l'tancar el navegador.</td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <h5>COM desactiven Les galetes ALS NAVEGADORS?</h5>

                                    <p>
                                        L'usuari pot, en qualsevol moment, permetre, bloquejar o eliminar les cookies
                                        instal·lades en el seu equip mitjançant la modificació dels paràmetres de configuració
                                        de el navegador en el vostre ordinador:
                                    </p>

                                    <ul>
                                        <li>
                                            Chrome Configuració <FontAwesomeIcon icon={faAngleRight} /> Mostra opcions avançades <FontAwesomeIcon icon={faAngleRight} /> Privadesa <FontAwesomeIcon icon={faAngleRight} /> Configuració de
                                            l'contingut
                                            <br />
                                            Per a més informació, podeu consultar el suport de Google o l'Ajuda de el navegador.
                                        </li>
                                        <li>
                                            Explorer Eines <FontAwesomeIcon icon={faAngleRight} /> Opcions d'Internet <FontAwesomeIcon icon={faAngleRight} /> Privacitat <FontAwesomeIcon icon={faAngleRight} /> Configuració
                                            <br />
                                            Per a més informació, pot consultar el suport de Microsoft o l'Ajuda de el
                                            navegador.
                                        </li>
                                        <li>
                                            Firefox: Eines <FontAwesomeIcon icon={faAngleRight} /> Opcions <FontAwesomeIcon icon={faAngleRight} /> Privadesa <FontAwesomeIcon icon={faAngleRight} /> Històric <FontAwesomeIcon icon={faAngleRight} /> Configuració Personalitzada
                                            <br />
                                            per a més informació, podeu consultar el suport de Mozilla o l'Ajuda de el
                                            navegador.
                                        </li>
                                        <li>
                                            Safari Preferències <FontAwesomeIcon icon={faAngleRight} /> Seguretat
                                            <br />
                                            Per a més informació, pot consultar el suport d'Apple o l'Ajuda de el navegador
                                        </li>
                                    </ul>

                                    <p>
                                        La desactivació de les cookies no impedeix la navegació pel lloc web, encara que l'ús
                                        d'alguns dels seus serveis podrà ser limitat i, per tant, la seva experiència de
                                        navegació pot ser menys satisfactòria.
                                    </p>

                                    <h5>RETIRAR EL CONSENTIMENT</h5>

                                    <p>
                                        L'usuari podrà retirar en qualsevol moment el seu consentiment relacionat amb la
                                        Política de Cookies, i pot suprimir les galetes emmagatzemades en el seu equip a través
                                        dels ajustos i configuracions del seu navegador d'Internet, indicats anteriorment.
                                    </p>

                                    <h5>CONFIGURAR COOKIES</h5>

                                    <p>
                                        La present Política de Cookies es podrà modificar quan així ho exigeixi la legislació
                                        vigent en cada moment o quan hi hagués alguna variació en el tipus de cookies
                                        utilitzades en el lloc web. Per això, li recomanem revisar aquesta política cada vegada
                                        que accedeixi al nostre lloc web amb l'objectiu d'estar adequadament informat sobre com
                                        i per què fem servir les galetes.
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container py-4">
                        <div className="text-center">
                            {Lang('COPYRIGHT')}
                        </div>
                    </div>
                </section>
            </section>
        </div>
    )
}
