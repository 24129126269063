import { types } from '../types/types';

// const state = {
//     name: 'Daniel',
//     logged: true
// }


export const authReducer = (state = {}, action ) => {

    console.log(action.lang)
    const lang = action.lang
    switch ( action.type ) {
        case types.login:
            return {
                lang,
                ...action.payload,
                logged: true
            }

        case types.logout:
            return {
                lang,
                logged: false
            }
    
        default:
            return state;
    }

}

export const sessionReducer = (state = {}, action ) => {

    console.log(state, action)

    switch ( action.type ) {
        case types.openNavPrimary:
            return {
                ...action.payload,
                logged: true
            }

        case types.closeNavPrimary:
            return {
                logged: false
            }
    
        default:
            return state;
    }

}