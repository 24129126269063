import React from 'react';
import { Alert } from 'react-bootstrap';

export const AlertaMessage = ({alerta, type = 'danger'}) => {
    return (
        <Alert variant={type}>
            <Alert.Heading>{alerta?.data?.titulo}</Alert.Heading>
            <div dangerouslySetInnerHTML={ {__html: alerta?.data?.mensaje} }>
            </div>
        </Alert>
    );
};