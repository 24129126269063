import { useState, useEffect, useRef, useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../auth/AuthContext';
import { types } from '../types/types';
import axios from 'axios';
import { generateDataServerFake } from '../dataFake/dataFake';

export const useAxiosFake = (method, url, type, adapter, adapterSchema) => {

    const { user: { token, lang }, dispatch } = useContext(AuthContext);

    const history = useHistory();

    const isMounted = useRef(true)

    const [state, setState] = useState({ data: null, loading: null, error: null, status: null });

    useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const handleFetch = (values) => {

        setState({ data: null, loading: true, error: null });

        const axiosObject = {
            method,
            url: values?.id ? url + '/' + values.id : url,
            headers: {
                Authorization: token ? 'Bearer ' + token : '',
                responseType: type,
            },
            data: values?.body,
            params: values?.params
        }

        const dataFake = generateDataServerFake(method, url, adapterSchema)

        console.log(dataFake)

        const dataAdapted = adapter(dataFake)

        // console.log(dataAdapted)

        setTimeout(() => {

            setState({
                loading: false,
                error: null,
                data: dataAdapted.data,
                status: dataAdapted.status,
                setState // En caso sea un file, limpiar el state con null luego de la descarga
            });

        }, 700);

        // axios({
        //     method,
        //     url: values?.id ? url + '/' + values.id : url,
        //     headers: {
        //         Authorization: token ? 'Bearer ' + token : '',
        //         responseType: type,
        //     },
        //     data: values?.body,
        //     params: values?.params
        //   })
        //     .then((res) => {
        //         if (!existenErroresAuth(res) && isMounted.current) {
        //             setState({
        //                 loading: false,
        //                 error: null,
        //                 data: adapter(res),
        //                 setState // En caso sea un file, limpiar el state con null luego de la descarga
        //             });
        //         }
        //     })
        //     .catch((err) => {
        //         setState({
        //             data: null,
        //             loading: false,
        //             error: err
        //         })
        //     })
        //     .finally(() => {
        //         // setloading(false);
        //     });

    }

    const handleResetData = () => {
        setState({ data: null, loading: null, error: null });
    }

    return [handleFetch, state, handleResetData];
}