import React, { useEffect, useState, useContext } from 'react'
import { faCreditCard, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal, Table, Badge } from 'react-bootstrap'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../hooks/useAxios';
import { types } from '../../../types/types';
import { InputText } from '../../../components/Inputs';
import {validarIBAN} from '../../../helpers/helpers'
import { validateID } from '../../../helpers/ValidateDocumentoID';

export const DatosFacturacion = ({fetchTarifaData, formTarifa, handleInputChangeTarifa, valuesValidTarifa, fetchPagarData, setIndexFocusWizard, handleFormChangeTarifa}) => {
    //Iban prueba ES2600498064776374212747
    const [isValid, setIsValid] = useState(true);
    const [isValidDocument, setIsValidDocument] = useState('');

    const handleChangeIban = (e) => {
        const value = e.target.value;
        if(validarIBAN(value)){
            setIsValid(true);
        }else{
            handleFormChangeTarifa({...formTarifa, org_iban: ''})
            setIsValid(false);
        }
    };
    const handleChangeDocument = (e) => {
        const value = e.target.value;
        if(validateID(value)){
            setIsValidDocument('');
        }else{
            handleFormChangeTarifa({...formTarifa, org_nif_propietario_cuenta: ''})
            setIsValidDocument(true);
        }
    };
	return (
        <div className="row d-flex mt-3 justify-content-center align-items-center">
          <div className="col-8">
                <Table hover responsive className="border-success">
                    <tbody>
                      <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>Detalle de tarifa:</strong><br/>
                        </td>
                        <td>
                         {fetchTarifaData.data?.data?.data?.detalle || ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>Total :</strong><br/>
                        </td>
                        <td>
                          € {fetchTarifaData.data?.data?.data?.precio || ''}
                        </td>
                      </tr>
                      <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>Número de cuenta IBAN:</strong><br/>
                        </td>
                        <td>
                          <InputText
                                //label='N° cuenta Ib:'
                                className=''
                                name='org_iban'
                                placeholder="Ingrese un IBAN"
                                value={formTarifa.org_iban}
                                onChange={handleInputChangeTarifa}
                                onBlur={handleChangeIban}
                                validation={valuesValidTarifa}
                                fetchData={fetchPagarData}
                            />
                            {!isValid && (
                                <span className="text-danger">IBAN no válido</span>
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td style={{backgroundColor: '#f8f9fa', color: '#495057'}}>
                          <strong>CIF / NIF Propietario cuenta:</strong><br/>
                        </td>
                        <td>
                          <InputText
                                //label='N° cuenta Ib:'
                                className=''
                                name='org_nif_propietario_cuenta'
                                placeholder="Ingrese un CIF / NIF"
                                value={formTarifa.org_nif_propietario_cuenta}
                                onChange={handleInputChangeTarifa}
                                validation={valuesValidTarifa}
                                fetchData={fetchPagarData}
                                onBlur={handleChangeDocument}
                            />
                            {isValidDocument && <span className="text-danger">Documento no válido</span>}
                        </td>
                      </tr>
                    </tbody>
                </Table>   
            </div>   
            <div className="text-center">
                <Button disabled={isValidDocument || (!formTarifa.org_iban || !formTarifa.org_nif_propietario_cuenta) ? true : false} variant="outline-primary" className="mt-2 mb-1" size="" onClick={() => setIndexFocusWizard(1)}><FontAwesomeIcon icon={faArrowRight} className='me-1'/>Siguiente</Button>
            </div>
        </div>  
    )
}