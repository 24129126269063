import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { MsgInput } from '../MsgInput/MsgInput'
import { MsgInputServer } from '../MsgInput/MsgInputServer';
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers';

export const InputSelectAutocomplete = ({
    label,
    className = '',
    name,
    values = [],
    onChange,
    placeholder,
    validation = {},
    fetchData = {},
    disabled,
    value
}) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleSearchChange = (e) => {
        const inputValue = e.target.value;
        setSearchTerm(inputValue);

        const filtered = values.filter(country =>
            country.nombre.toLowerCase().includes(inputValue.toLowerCase())
        );

        if (!filtered) {
            onChange({ target: { name, value: '' } });
        }

        setFilteredCountries(filtered.slice(0, 10));
    };

    const handleFocus = () => {
        //setSearchTerm('');
        setFilteredCountries(values.slice(0, 10));
        setShowDropdown(true);
    };

    useEffect(() => {
        if (values.length > 0 && value) {
            const selectedCountry = values.find(country => country.id === parseInt(value));
            if (selectedCountry) {
                setSearchTerm(selectedCountry.nombre);
            }
        }
    }, [value, values]);

    const handleSelectCountry = (countryName) => {
        const selectedCountry = values.find(country => country.nombre === countryName);
        if (selectedCountry) {
            setShowDropdown(false);
            onChange({ target: { name, value: selectedCountry.id } });
        }
    };

    const handleBlur = () => {
        setTimeout(() => {
            setShowDropdown(false);
        }, 200);
    };

    return (
        <>
        <Form.Group controlId="autocompleteSelect">
            {label && <Form.Label className='fw-500'>{label}</Form.Label>}
            <Form.Control
                className={`${className} ${(validation ? getMsgInputColor(validation, name) : false) || getMsgInputServerColor(validation, name) ? 'is-invalid' : ''}`}
                as="input"
                value={searchTerm}
                onChange={handleSearchChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder={placeholder || "Buscar..."}
                aria-label="Buscar"
                disabled={disabled}
            />
            {showDropdown && filteredCountries.length > 0 && (
                <div className="dropdown-menu border-0 shadow show" style={{ position: 'absolute', zIndex: 1000, minWidth: '100% !important', width: '100% !important' }}>
                    {filteredCountries.map((country) => (
                        <div
                            key={country.id}
                            className="dropdown-item"
                            onClick={() => handleSelectCountry(country.nombre)}
                            style={{ cursor: 'pointer' }}
                        >
                            {country.nombre}
                        </div>
                    ))}
                </div>
            )}
        </Form.Group>
        {
            validation && <MsgInput obj={validation} name={name} />
        }
        <MsgInputServer obj={fetchData?.data} name={name} />
        </>
    );
};