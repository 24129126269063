import React, { useEffect, useState, useContext, useRef  } from 'react'
import { faCreditCard, faTimes, faUniversity, faCheckCircle  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Modal } from 'react-bootstrap'
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext'
import { useForm } from '../../../hooks/useForm';
import { types } from '../../../types/types';
import './PaymentInfoTable.css';
import { MsgInputServer } from '../../../components/MsgInput/MsgInputServer';
import Wizard from "../../../components/Wizard/Wizard";
import { images } from '../../../helpers/images';

export const PaymentMethodModal = ({isOpen, setMetodoPago}) => {
     
    const { dispatch } = useContext(AuthContext);
    const [fetchLogout, fetchLogoutData] = useAxios('delete', `/auth/logout`)
    const [formMetodo, 
        handleInputChangeMetodo, 
        handleFormChangeMetodo, 
        handleResetMetodo] = useForm({
            metodo_pago: 'R',
        })

    const handleLogout = (e) => {
        e.preventDefault()
        fetchLogout()
        const lang = JSON.parse(localStorage.getItem(process.env.REACT_APP_MAIN_VARIABLE))?.lang || process.env.REACT_APP_LANGUAGE_DEFAULT;
        dispatch({
            type: types.logout,
            lang
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setMetodoPago(formMetodo.metodo_pago);
    }

    const handleChange = (event) => {
        handleFormChangeMetodo({
            ...formMetodo,
            metodo_pago: event.target.value
        })
    };

	return (
        <Modal show={isOpen} size={''} style={{backdropFilter: ' blur(10px)'}} centered>
            <Modal.Header>
                <Modal.Title className='h5'>Seleccione un Método de pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 <div className="row d-flex">
                 <div className="col-lg-12 text-center">
                  <div className="grid-wrapper grid-col-auto">
                    <label htmlFor="radio-card-1" className="radio-card">
                      <input 
                      type="radio" 
                      name="radio-card" 
                      value="R"
                      id="radio-card-1" 
                      defaultChecked={formMetodo.metodo_pago === 'R' ? true : false}
                      onChange={handleChange}
                      />
                      <div className="card-content-wrapper">
                        <span className="check-icon"></span>
                        <div className="card-content text-center">
                          <FontAwesomeIcon icon={faUniversity} size={'3x'} className="pb-2"/>
                          <h4>Domiciliación</h4>
                        </div>
                      </div>
                    </label>

                    <label htmlFor="radio-card-2" className="radio-card">
                      <input 
                      type="radio" 
                      name="radio-card" 
                      value="T"
                      id="radio-card-2" 
                      defaultChecked={formMetodo.metodo_pago === 'T' ? true : false}
                      onChange={handleChange}
                      />
                      <div className="card-content-wrapper">
                        <span className="check-icon"></span>
                        <div className="card-content text-center">
                          <FontAwesomeIcon icon={faCreditCard} size={'3x'} className="pb-2"/>
                          <h4>Transferencia Bancaria</h4>
                        </div>
                      </div>
                    </label>
                    </div>
                  </div>  
                 </div> 
            </Modal.Body>
            <Modal.Footer>
                <ButtonSpinner
                    variant="outline-primary" size="sm" type="submit" className="mt-2 mb-1"
                    handleSubmit={handleSubmit} fetchDataLoading={[false]}
                    value='Continuar' icon={<FontAwesomeIcon icon={faCheckCircle} className='me-1' />}
                />
                <Button variant="outline-danger" className="mt-2 mb-1" size="sm" onClick={handleLogout}><FontAwesomeIcon icon={faTimes} className='me-1'/> Cerrar sesión</Button>
            </Modal.Footer> 
        </Modal>
    )
}