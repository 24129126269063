import React, { useEffect, useRef, useState } from 'react'
import { Table, Breadcrumb, ListGroup, Form, Tabs, Tab, Badge, Button, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faHome, faSave } from '@fortawesome/free-solid-svg-icons'

import './PlantillasMensajes.css'
import { useFetch } from '../../hooks/useFetch';
import { Loader } from '../../components/Loader/Loader';

import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/code';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { useLang } from '../../language'
import { useForm } from '../../hooks/useForm'
import { getMsgInputColor, getMsgInputServerColor } from '../../helpers/helpers'
import { useValidator } from '../../hooks/useValidator'
import { MsgInput } from '../../components/MsgInput/MsgInput'
import { MsgInputServer } from '../../components/MsgInput/MsgInputServer'
import { InputText } from '../../components/Inputs/InputText'
import { ButtonSpinner } from '../../components/ButtonSpinner/ButtonSpinner'
import { useAxios } from '../../hooks/useAxios'

export const PlantillasMensajes = ({ namePage }) => {

    const Lang = useLang();

    const [fetchPlantillas, fetchPlantillasData] = useAxios('get', `/plantillas`)

    const [fetchPlantilla, fetchPlantillaData] = useAxios('get', `/plantillas`)

    const [fetchActualizarPlantilla, fetchActualizarPlantillaData] = useAxios('put', `/plantillas`)

    const [activeLink, setactiveLink] = useState('confirmar_registro')

    useEffect(() => {
        fetchPlantillas()
        fetchPlantilla({
            id: 'confirmar_registro'
        })
    }, [])

    useEffect(() => {

        resetFormValuesPlantilla()
        console.log(fetchPlantillaData)
        if (fetchPlantillaData.data?.data) {
            handleFormChangePlantilla({
                ...fetchPlantillaData.data.data.data
            })
        }
    }, [fetchPlantillaData])

    useEffect(() => {

        console.log(fetchPlantillasData)
    }, [fetchPlantillasData])

    useEffect(() => {

        resetValuesValidPlantilla()
        fetchPlantilla({
            id: activeLink
        })
    }, [activeLink])


    const [formValuesPlantilla, handleInputChangePlantilla, handleFormChangePlantilla, resetFormValuesPlantilla] = useForm({
        pe_id: '',
        pe_nombre: '',
        pe_descripcion: '',
        pe_remitente: '',
        pe_remitente_email: '',
        pe_asunto: '',
        pe_html: '',
        pe_fecha_creacion: '',
        pe_fecha_modificacion: '',
        variables: []
    })


    useEffect(() => {

        console.log(formValuesPlantilla)
    }, [formValuesPlantilla])

    const [valuesValidPlantilla, handleVerifiedValuesPlantilla, resetValuesValidPlantilla] = useValidator({
        pe_id: {},
        // pe_nombre: {
        //     required: {
        //         isOk: true,
        //         msgError: [`El nom és necessari.`]
        //     }
        // },
        pe_descripcion: {
            isOk: true,
            msgError: [`La descripción es obligatoria.`]
        },
        pe_remitente: {
            isOk: true,
            msgError: [`El remitente es obligatorio.`]
        },
        pe_remitente_email: {
            isOk: true,
            msgError: [`El email del remitente es obligatorio.`]
        },
        pe_asunto: {
            isOk: true,
            msgError: [`El asunto es obligatorio.`]
        },
        pe_html: {
            isOk: true,
            msgError: [`El contenido del correo es obligatorio.`]
        },
        // pe_fecha_creacion: {
        //     required: {
        //         isOk: true,
        //         msgError: [`La contrasenya és necessària.`]
        //     }
        // },
        // pe_fecha_modificacion: {
        //     required: {
        //         isOk: true,
        //         msgError: [`La confirmació de la contrasenya és necessària.`]
        //     }
        // },
    })

    const [dataInicial, setDataInicial] = useState("<p>Probandoooo Escribe aqui...</p>");
    // const [contentEditor, setContentEditor] = useState();

    const handleEditorChange = (content, editor) => {

        console.log('Content was updated:', content);
        // setContentEditor(content);

        const target = {
            name: 'pe_html',
            value: content,
        }
        handleInputChangePlantilla({ target })
    }

    const handleSubmitPlantilla = (e) => {
        e.preventDefault()
        if (handleVerifiedValuesPlantilla(formValuesPlantilla)) {

            fetchActualizarPlantilla({
                body: {
                    descripcion: formValuesPlantilla.pe_descripcion,
                    asunto: formValuesPlantilla.pe_asunto,
                    html: formValuesPlantilla.pe_html,
                    id: formValuesPlantilla.pe_id,
                    nombre: formValuesPlantilla.pe_nombre,
                    remitente: formValuesPlantilla.pe_remitente,
                    remitente_email: formValuesPlantilla.pe_remitente_email,
                }
            })
        }
    }

    useEffect(() => {

        if (fetchActualizarPlantillaData.data?.status === 200) {

            fetchPlantilla({
                id: activeLink
            })
        }
    }, [fetchActualizarPlantillaData])

    return (
        <div className="container-inner row justify-content-between">
            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    {Lang('PLANTILLAS_MENSAJES')}
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className='col-xl-3'>
                <div className='Column-small shadow-sm sticky-top listgroup-plantillas'>

                    <h5>{Lang('PLANTILLAS')}</h5>
                    <ListGroup variant="flush" className='mt-3'>
                        {
                            fetchPlantillasData?.data?.data?.data?.map((item, index) => {

                                return <ListGroup.Item key={item.pe_id} className={`${item.pe_id === activeLink ? 'active' : ''}`} onClick={() => setactiveLink(item.pe_id)}>{index + 1}. {item.pe_nombre}</ListGroup.Item>
                            })
                        }
                    </ListGroup>
                </div>
            </div>
            <div className="col-xl-9">

                <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
                    {
                        fetchPlantillaData.loading ?
                            <div className="d-flex py-5 justify-content-center">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size=""
                                    role="status"
                                    aria-hidden="true"
                                    className='me-2'
                                />
                            </div>
                            : <>

                                <div className="d-flex justify-content-between mb-2">
                                    <div>
                                        <h5>{formValuesPlantilla.pe_nombre}</h5>
                                    </div>
                                </div>

                                <Form className='row'>
                                    <Form.Group className="col-12 mb-4">
                                        <InputText
                                            label={Lang('DESCRIPCION_PLANTILLA')}
                                            className=''
                                            name='pe_descripcion'
                                            value={formValuesPlantilla.pe_descripcion}
                                            onChange={handleInputChangePlantilla}
                                            validation={valuesValidPlantilla}
                                            fetchData={fetchActualizarPlantillaData}
                                        />
                                    </Form.Group>
                                    {/* <Form.Group className="col-12 mb-4" controlId="exampleForm.ControlInput7">
                                                    <Form.Label></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        onChange={handleInputChangePlantilla}
                                                        value={formValuesPlantilla.pe_descripcion}
                                                        name='pe_descripcion'
                                                        autoComplete="one-time-code"
                                                        className={`${getMsgInputServerColor(fetchActualizarPlantillaData, 'descripcion') || getMsgInputColor(valuesValidPlantilla, 'pe_descripcion') ? 'is-invalid' : ''}`}
                                                    />
                                                    <MsgInput obj={valuesValidPlantilla} name={'pe_descripcion'} />
                                                    <MsgInputServer obj={fetchActualizarPlantillaData?.data} name={'descripcion'} />
                                                </Form.Group> */}

                                    <Form.Group className="col-12 col-sm-6 col-lg-5 col-xl-4 mb-4">
                                        <InputText
                                            label={Lang('REMITENTE')}
                                            className=''
                                            name='pe_remitente'
                                            value={formValuesPlantilla.pe_remitente}
                                            onChange={handleInputChangePlantilla}
                                            validation={valuesValidPlantilla}
                                            fetchData={fetchActualizarPlantillaData}
                                        />
                                    </Form.Group>

                                    {/* <Form.Group className="col-12 col-sm-6 col-lg-5 col-xl-4 mb-4" controlId="exampleForm.ControlInput7">
                                                    <Form.Label>{Lang('REMITENTE')}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        onChange={handleInputChangePlantilla}
                                                        value={formValuesPlantilla.pe_remitente}
                                                        name='pe_remitente'
                                                        autoComplete="one-time-code"
                                                        className={`${getMsgInputServerColor(fetchActualizarPlantillaData, 'pe_remitente') || getMsgInputColor(valuesValidPlantilla, 'pe_remitente') ? 'is-invalid' : ''}`}
                                                    />
                                                    <MsgInput obj={valuesValidPlantilla} name={'pe_remitente'} />
                                                    <MsgInputServer obj={fetchActualizarPlantillaData?.data} name={'pe_remitente'} />
                                                </Form.Group> */}

                                    <Form.Group className="col-12 col-sm-6 col-lg-5 col-xl-4 mb-4">
                                        <InputText
                                            label={Lang('CORREO_REMITENTE')}
                                            className=''
                                            name='pe_remitente_email'
                                            value={formValuesPlantilla.pe_remitente_email}
                                            onChange={handleInputChangePlantilla}
                                            validation={valuesValidPlantilla}
                                            fetchData={fetchActualizarPlantillaData}
                                        />
                                    </Form.Group>

                                    {/* <Form.Group className="col-12 col-sm-6 col-lg-5 col-xl-4 mb-4" controlId="exampleForm.ControlInput7">
                                                    <Form.Label>{Lang('CORREO_REMITENTE')}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        onChange={handleInputChangePlantilla}
                                                        value={formValuesPlantilla.pe_remitente_email}
                                                        name='pe_remitente_email'
                                                        autoComplete="one-time-code"

                                                        className={`${getMsgInputServerColor(fetchActualizarPlantillaData, 'pe_remitente_email') || getMsgInputColor(valuesValidPlantilla, 'pe_remitente_email') ? 'is-invalid' : ''}`}
                                                    />
                                                    <MsgInput obj={valuesValidPlantilla} name={'pe_remitente_email'} />
                                                    <MsgInputServer obj={fetchActualizarPlantillaData?.data} name={'pe_remitente_email'} />
                                                </Form.Group> */}

                                    <Form.Group className="col-12 col-sm-12 col-lg-12 col-xl-10 mb-4">
                                        <InputText
                                            label={Lang('ASUNTO')}
                                            className=''
                                            name='pe_asunto'
                                            value={formValuesPlantilla.pe_asunto}
                                            onChange={handleInputChangePlantilla}
                                            validation={valuesValidPlantilla}
                                            fetchData={fetchActualizarPlantillaData}
                                        />
                                    </Form.Group>


                                    {/* <Form.Group className="col-12 col-sm-12 col-lg-12 col-xl-10 mb-4" controlId="exampleForm.ControlInput7">
                                                    <Form.Label>{Lang('ASUNTO')}</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        onChange={handleInputChangePlantilla}
                                                        value={formValuesPlantilla.pe_asunto}
                                                        name='pe_asunto'
                                                        autoComplete="one-time-code"

                                                        className={`${getMsgInputServerColor(fetchActualizarPlantillaData, 'pe_asunto') || getMsgInputColor(valuesValidPlantilla, 'pe_asunto') ? 'is-invalid' : ''}`}
                                                    />
                                                    <MsgInput obj={valuesValidPlantilla} name={'pe_asunto'} />
                                                    <MsgInputServer obj={fetchActualizarPlantillaData?.data} name={'pe_asunto'} />
                                                </Form.Group> */}

                                    <Form.Group className="col-xl-8 mb-4" controlId="exampleForm.ControlInput7">
                                        <Form.Label>{Lang('MENSAJE')}</Form.Label>

                                        <Editor
                                            // initialValue={dataInicial}
                                            init={{
                                                skin: false,
                                                branding: false,
                                                content_css: false,
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'link image',
                                                    'table paste',
                                                    'code'
                                                ],
                                                toolbar:
                                                    'undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat | help code'
                                            }}
                                            value={formValuesPlantilla.pe_html}
                                            onEditorChange={handleEditorChange}

                                        />
                                    </Form.Group>

                                    <Form.Group className="col-xl-4 mb-4" controlId="exampleForm.ControlInput7">
                                        <Form.Label>{Lang('CODIGOS_ADICIONALES')}</Form.Label>
                                        <ListGroup as="ol" numbered>
                                            {
                                                formValuesPlantilla?.variables?.map(item => {
                                                    return (
                                                        <ListGroup.Item
                                                            as="li"
                                                            className="d-flex justify-content-between align-items-start"
                                                            key={item.var_id}
                                                        >
                                                            <div className="">
                                                                <div className="fw-bold">{item.var_id}</div>
                                                                <small>{item.var_desc}</small>
                                                            </div>
                                                        </ListGroup.Item>
                                                    )
                                                })
                                            }
                                        </ListGroup>
                                    </Form.Group>
                                    <div className="pt-1 d-flex justify-content-end">
                                        {/* <Button
                                                        variant="primary"
                                                        type='submit'
                                                        className='px-4'
                                                        disabled={fetchActualizarPlantillaData.loading}
                                                        onClick={handleSubmitPlantilla}
                                                    >
                                                        {
                                                            fetchActualizarPlantillaData.loading ?
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className='me-2'
                                                                />
                                                                : ''
                                                        }
                                                        <FontAwesomeIcon icon={faSave} className='me-1' /> Desar
                                                    </Button> */}
                                        <ButtonSpinner
                                            variant="primary" type="submit" className="px-3"
                                            handleSubmit={handleSubmitPlantilla} fetchDataLoading={[fetchActualizarPlantillaData.loading]}
                                            value='Guardar' icon={<FontAwesomeIcon icon={faSave} className='me-1' />}
                                        />
                                    </div>
                                </Form>
                            </>
                    }

                </div>
            </div>
        </div>

    )
}